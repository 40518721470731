@import "../../../../../shared/style/Variables.scss";

.notification-container {
  background-color: white;
  border: 1px solid $border-color !important;
  border-radius: 8px;
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 90% !important;
    display: flex !important;
    justify-content: space-evenly !important;
    .ant-tabs-tab {
      flex-grow: 1 !important;
      justify-content: center;
      div {
        p {
          color: #aaaaaa;
          font-size: 24px;
          margin: 0;
          padding: 10px;
          display: flex;
          align-items: center;
          span {
            margin-left: 10px;
            font-size: 16px;
            display: inline-flex;
            background-color: $bg-color;
            color: #cfcfcf;
            border-radius: 8px;
            padding: 8px 16px !important;
          }
        }
      }
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        p {
          color: #222 !important;
          span {
            background-color: $black !important;
            color: $white !important;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

.ant-tabs-ink-bar {
  background: #cfcfcf !important;
}

.day {
  background-color: $bg-color;
  text-transform: uppercase;
  padding: 15px 30px;
  font-size: 21px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}
@media screen and (max-width: 480px) {
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 0 !important;
  }
  .tab-unread {
    font-size: 18px !important;
  }
  .notification-container {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        div {
          p {
            span {
              font-size: 14px !important;
              padding: 8px 16px !important;
            }
          }
        }
      }
    }
  }

  .day {
    font-size: 18px;
  }
}
