.download-details-btn{
    background-color: #c71e21;
    color: white;
    padding: 1rem;
    height: fit-content;
    border-radius: 8px;
}
.download-row{
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
}