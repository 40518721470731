.donut-big {
  // .apexcharts-legend{

  //     top: 200px !important ;
  //     right: 200px !important;

  // }
  // .ant-modal-content {
  //   height: 100%;
  //   min-height: 600px;
  // }
  // .ant-modal-body {
  //   min-height: 500px;
  // }
  // .apexcharts-canvas {
  //   min-height: 500px;
  // }
  // .apexcharts-svg {
  //   min-height: 500px;
  //   foreignObject {
  //     min-height: 500px;
  //   }
  // }
  .apexcharts-inner {
    width: 100% !important;
  }
  .apexcharts-text {
    text-align: left;
    font: normal normal bold 38px/46px Roboto !important;
    letter-spacing: 0.16px;
    color: #131523;
    opacity: 1;
    width: 97px;
    height: 46px;
  }
  .apexcharts-datalabel-value {
    text-align: left;
    font: normal normal bold 38px/46px Roboto !important;
    letter-spacing: 0.16px;
    color: #131523;
    opacity: 1;
    width: 97px;
    height: 46px;
  }
}
.apexcharts-legend-series {
  display: flex;
  align-items: center;
  .apexcharts-legend-marker {
    height: 16px !important;
    width: 16px !important;
    border-radius: 0px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
}
.apexcharts-legend-text {
  font-size: 16px !important;
}
