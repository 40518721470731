@import "../../../../../shared/style/Variables.scss";

.dashboard-donut {
  position: absolute;
  right: 2px;
  top: 2px;
}
.dashboard-order {
  cursor: pointer;
}

.dashboard-bg-color,
.dashboard-main-content-layout {
  background-color: $white;
  padding: 0 5rem;
  .search {
    padding: 0;
  }
  .ant-btn.ant-btn-block {
    width: 94%;
  }
}
.dashboard-row {
  padding: 40px 0;
}
.dashboard-box {
  border-radius: 10px;
  border-style: solid;
  border-color: #0000000b;
  min-height: 120px;
  padding: 15px;

  .dashboard-box-title {
    text-align: left;
    font: normal normal normal 16px/19px Roboto;
    letter-spacing: 0.1px;
    color: #7e84a3;
    opacity: 1;
  }

  .dashboard-box-content {
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    font-family: Roboto;
    letter-spacing: 0.16px;
    color: #131523;
    opacity: 1;
  }
}

.insight-box {
  border-style: solid;
  border-color: #0000000b;
  min-height: 547px;
  border-radius: 24px;
  margin-bottom: 15px;
  padding: 0 !important;
  .ant-divider-horizontal {
    margin: 15px 0;
  }
  .insight-img {
    color: #c71e21;
    margin: 0 10px;
  }
  Button {
    text-align: left;
    outline: none;
    border: none;
    font: normal normal medium Roboto;
    font-weight: 700;
    letter-spacing: 0px;
    color: #0058ff;
    opacity: 1;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .insight-box-header {
    .insight-box-title {
      text-align: left;
      font-weight: 700;
      font-family: Roboto;
      letter-spacing: 0px;
      color: #383838;
      opacity: 1;
      padding: 15px 20px 0px !important;
      position: relative;
      .admin-range-picker {
        position: absolute;
        right: 5%;
        background: transparent;
        outline: none;
        padding: 5px;
        border: 1px solid lightgray;
        border-radius: 3px;
        width: 240px;
        top: 30%;
        .ant-picker-input {
          .ant-picker-suffix {
            color: #c71e21 !important;
          }
        }
      }
      .admin-datepicker {
        position: absolute;
        right: 5%;
        background: transparent;
        outline: none;
        padding: 5px;
        border: 1px solid lightgray;
        border-radius: 3px;
        width: 120px;
        top: 30%;
        /* transform: translateY(-50%); */
        .ant-picker-input {
          input {
            text-align: center;
          }
          .ant-picker-suffix {
            color: #c71e21 !important;
          }
        }
      }
    }
  }
  .insight-content-box {
    min-height: 395px;
    padding: 0px 20px !important;
  }
  .insight-photo {
    max-width: 50px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    max-height: 50px;
    border-radius: 8px !important;
  }
  .top-performing {
    border-radius: 50% !important;
    max-width: 50px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    max-height: 50px;
  }
  .insight-button {
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
  }
  .insight-box-month {
    text-align: left;
    font: 700 14px/20px Roboto;
    letter-spacing: 0px;
    color: #131523;
    opacity: 1;
    height: 17px;
  }
  .insight-box-span {
    text-align: right;
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #131523;
    opacity: 1;
    // word-break: break-all;
    // width: 149%;
    text-align: center;
  }
}
.btn {
  padding: 10px;
  margin: 10px;
  border: 1px;
  border-style: solid;
  border-radius: 5px;
  min-height: 50px;
}
.purplebtn {
  background-color: #f2e9ff !important;
  color: #51459e;
  border-color: #51459e60;
}
.orangebtn {
  background-color: #fef3f0 !important;
  color: #ac6755;
  border-color: #ac675586;
}

.ant-progress-line {
  padding: 5px;
  // width: 50%;
}
.detail-card {
  padding: 10px 0;
  .detailCard-title {
    padding: 0 10px;
    text-align: left;
    font: 700 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;
  }
  p {
    padding-top: 5px;
    text-align: left;
    font: normal normal normal 12px/20px Roboto;

    margin: 0;
    letter-spacing: 0px;
    color: #7e84a3;
    opacity: 1;
  }
  .detailCard-number {
    text-align: right;
    font: 700 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;
  }
}

@media screen and (max-width: 1440px) {
  // .ant-progress-line {
  //   width: 0%;
  // }
}

@media screen and (max-width: 1024px) {
  .dealer-dashboard-btns {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row !important;
    .dealer-dashboard-btn {
      height: fit-content !important;
      max-width: 278px !important;
    }
  }
  .search {
    padding: 0;
  }
  .dashboard-box {
    .dashboard-box-content {
      font-size: 24px;
    }
  }
  .dashboard-donut {
    .donut-small {
      height: 50px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard-bg-color,
  .dashboard-main-content-layout {
    padding: 0 1.4rem;
  }
  .dealer-dashboard-btns {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row !important;
    .dealer-dashboard-btn {
      height: fit-content !important;
      max-width: 278px !important;
    }
  }
}
@media screen and (max-width: 480px) {
  .dashboard-bg-color,
  .dashboard-main-content-layout {
    padding: 0 1rem;
  }
  .page-title {
    margin-bottom: 15px;
  }
  .insight-box .insight-box-header .insight-box-title .admin-datepicker {
    position: relative;
    margin-left: 20px;
    margin-top: 5px;
  }
}

.dealer-box {
  min-height: 154px !important;
  max-height: 154px !important;
  min-width: fit-content !important;
}

.dealer-dashboard-inner-padding {
  padding: 1.6em 0em;
  .fav-products-box {
    order: 1;
  }
  .month-box {
    order: 3;
  }
  .order-box {
    order: 2;
  }
}
.dealer-dashboard-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
}
.dealer-dashboard-btn {
  // width: fit-content !important;
  height: fit-content !important;
  max-width: 380px;
}

.dashboard-admin-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  .dashboard-box {
    flex-grow: 1;
    background-color: #fff !important;
    padding: 25px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    min-width: 292px;
    max-height: 174px;
    min-height: 174px;
  }
  .dashboard-order {
    position: relative;
    .donut {
      position: absolute;
      right: 7%;
      top: 54%;
      transform: translateY(-50%);
    }
    .arrow {
      position: absolute;
      top: 5%;
      right: 5%;
      transform: rotate(-45deg);
      cursor: pointer;
      svg {
        height: 1.3em !important;
        width: 1.3em !important;
      }
    }
  }
}

.admin-divide {
  padding: 0px 20px !important;
}

.dashboard-page-title {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.month-wise-wrapper {
  margin-left: -12px;
  margin-right: -12px;
  width: 100%;
  padding: 15px;
  .rev-col {
    display: flex;
    justify-content: center;
  }
}
.month-wise-title {
  color: #7e84a3;
}

// @media screen and (max-width: 600px) {
//   .dashboard-bg-color,
//   .dashboard-main-content-layout {
//     padding: 0px 15px;
//   }
//   .dealer-dashboard-btns {
//     justify-content: center;
//     align-items: center;
//     padding: 0 20px;
//   }
//   // .insight-box .insight-box-header .insight-box-title {
//   //   display: flex;
//   //   align-items: center;
//   //   gap: 10px;
//   // }
//   .insight-box .insight-box-header .insight-box-title .admin-datepicker {
//     position: relative;
//   }
// }

@media screen and (max-width: 350px) {
  .insight-box .insight-photo {
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
  }
}
.dealer-donut {
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    svg {
      foreignObject {
        .apexcharts-legend {
          display: none !important;
        }
      }
    }
  }
}

.dealer-chart-info {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    p {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 10px;
      .color {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
    .percent {
      font-weight: bold;
    }
  }
}
