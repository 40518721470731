@import "../shared/style/Variables.scss";
@import "../shared/style/antd/_antd.scss";
@import "../shared/style/main.scss";
@import "../shared/style/utility.scss";
//@import "../shared/style/fonts/icons/style.css";

body {
  margin: 0;
  padding: 0;
  font-family: $font-primary !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
overflow-x: hidden;
  // background: $page-background;

  min-height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-form input[type="file"]:focus,
.ant-form input[type="radio"]:focus,
.ant-form input[type="checkbox"]:focus {
  outline: 0;
  outline-offset: 0;
}
.ant-breadcrumb li:last-child{
  margin: auto 0;
}