@import "../../../../../shared/style/Variables.scss";

.manage-order-main-content {
  background-color: #f8f8fb !important;
  padding: 15px;

  .manage-order-title {
    text-align: left;
    font: Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    opacity: 1;
    // padding: 10px 0;
  }

  .ant-table-filter-column {
    justify-content: center !important;
    // flex: 1 0;
  } 
  .ant-table-column-title {
  flex: none;
  text-align: left;
  font: normal normal  16px/19px Roboto !important;
  letter-spacing: 1.16px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  // text-decoration: none !important;
  }

   .ant-table tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    white-space: normal;
  }
  .orderid{
    text-align: left;
    font-weight: bold !important;
    letter-spacing: 0.23px;
    color: #131523;
    opacity: 1;
    cursor: pointer;
    // text-decoration: underline;
  }
  .placeon{
    text-align: center;
    font: normal normal normal 16px/22px Roboto;
    letter-spacing: 0.11px;
    color: #131523;
    opacity: 1;
  }
}
