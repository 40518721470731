@import "../../../../shared/style/Variables.scss";

.notification-detail-card {
  margin: 30px;
  padding: 20px 0px;
  display: flex;
  gap: 35px;
  border-bottom: 1px solid $border-color;
  .image {
    position: relative;
    height: 100%;
    img {
      width: 120px;
      height: 120px;
      border-radius: 16px;
    }
    .icon {
      position: absolute;
      bottom: -10%;
      right: -10%;
      border-radius: 100%;
      color: $white;
      display: inline-flex;
      padding: 10px;
      background-color: $primary-color;
    }
  }
  .details {
    padding-right: 30px;
    .title {
      padding: 0;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    .info {
      margin: 0;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .time-tag {
      display: inline-block;
      padding: 10px;
      border-radius: 8px;
      background-color: $bg-color;
      font-size: 16px;
    }
  }
}

.notification-detail-card:last-child {
  border: none;
}

@media screen and (max-width: 480px) {
  .notification-detail-card {
    margin: 20px;
    padding: 10px 0;
    gap: 15px;
    border-bottom: 1px solid $border-color;
    .image {
      img {
        width: 60px;
        height: 60px;
      }
    }
    .details {
      padding-right: 0px;
      .title {
        width: 100%;
        overflow-wrap: break-word;

        font-size: 14px !important;
      }
      .info {
        font-size: 12px !important;
      }
      .time-tag {
        font-size: 12px !important;
      }
    }
  }
}
