@import "../shared/style/Variables.scss";

  .resetPassword-form-row {
    .resetPassword-form-wrapper-column {
      padding: 40px;
    margin-left: 150px;
    // margin:0 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    .login-input {
      width: 400px;
      height: 60px;
      border: 1px solid #d0d0d2;
      border-radius: 8px;
      opacity: 1;
    }
    .reset-btn {
      width: 400px;
      height: 60px;
      color: white;
      background-color: #c71e21;
      margin: 0 !important;
      border-radius: 8px;
    }
      .resetPassword-form-wrapper {
        align-items: center;
        justify-content: center;

        .logo {
          margin-bottom: 60px;
        }

        .resetPassword-title {
          font-family: Roboto;
          font-weight: bold;
          font-size: 40px;
          letter-spacing: 0;
        }
        .resetPassword-text {
          font-family: Roboto;
          font-weight: regular;
          letter-spacing: 0;
          font-size: 18px;
          color: #a7a7a7;
          margin-bottom: 40px;
        }
        width: 400px;
        .form-logo {
          max-height: 45px;
          margin-bottom: 50px;
        }

        .form-text {
          color: gray;
          font-weight: 200;
          font-size: xx-small;
        }
      }

    }
    .resetPassword-text-column {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      // justify-content: center;

      // max-width: 100%;

      .resetPassword-text-wrapper {
        .resetPassword-prop-image {
          height: 100vh;
          object-fit: cover;
          width: 100%;
        }
        // .heading{
        //   font-size: 3.5rem;
        //   line-height: 1;
        //   color: #66c6bd;
        //   font-weight: 100;
        // }
        // .bar-wrapper{
        //   display: flex;
        //   .bar{
        //     height: 20px;
        //     flex: 1;
        //     background: linear-gradient(90deg, #1fa9e1 0 12%, #7bc142 12% 24%, #f48028 24% 36%, #66c6bd 36% 100%);
        //   }
        //   .bar-text{
        //     margin-left: 10px;
        //   }
        // }
      }
    }
  }

  
  @media screen and(max-width:1024px) {
    .resetPassword-form-wrapper-column {
      padding: 40px;
      margin-left: 30px !important;
      .login-input {
        width: 300px !important;
      }
      .reset-btn {
        width: 300px !important;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .resetPassword-form-wrapper-column {
      border-top-left-radius: 50px;
      position: absolute !important;
      top: 30% !important;
      z-index: 1;
      background-color: white;
      width: 100% !important;
      margin: 0 !important;
      padding: 30px !important;
      padding-left: 50px !important;
      .resetPassword-title {
        font-size: 24px !important;
      }
      .login-text {
        font-size: 16px;
        margin-bottom: 20px !important;
      }
      .logo {
        padding-top: 20px;
        padding-left: 25%;
        margin-bottom: 20px !important;
        img {
          height: 50px;
        }
      }
      .login-input {
        height: 50px !important;
      }
      .reset-btn {
        height: 50px !important;
      }
    }
    .resetPassword-text-column {
      position: absolute !important;
      top: 0% !important;
      height: 50px !important;
      .login-prop-image {
        height: 500px !important;
        width: 100% !important;
      }
    }
  }
  
  
  
  @media screen and (max-width: 480px) {
    .resetPassword-form-wrapper-column {
      padding: 50px 30px !important ;
      .logo {
        margin: auto !important;
        padding: 0px !important;
        margin-bottom: 20px !important;
      }
      .login-input {
  width: 100% !important;
      }
      .reset-btn {
        width: 100% !important;
      }
    }
  }
