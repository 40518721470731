.buyxgetyselect {
  width: 100% !important;
  .ant-select-selector {
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5715;
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
    width: 100% !important;
    .ant-select-selection-placeholder {
      font-size: 14px !important;
      text-align: justify !important;
    }
  }
}
.w-100 {
  width: 100%;

  .ant-form-item-row {
    width: 100%;
  }
}
.mr-2 {
  margin-right: 10px;
}

.buyxgetyTree {
  > div > div > div > div {
    display: flex !important;
    flex-direction: column !important;
    gap: 5px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.buyXGetYContainer {
  overflow: hidden !important;
}
.admin-buyxgety-modal {
  width: 55% !important;
}

@media screen and (max-width: 1440px) {
  .admin-buyxgety-modal {
    width: 65% !important;
  }
}

@media screen and (max-width: 1280px) {
  .admin-buyxgety-modal {
    width: 75% !important;
  }
}

@media screen and (max-width: 1024px) {
  .admin-buyxgety-modal {
    width: 85% !important;
  }
}
@media screen and (max-width: 768px) {
  .admin-buyxgety-modal {
    width: 95% !important;
  }
}

@media screen and (max-width: 480px) {
  .buyXGetYContainer {
    overflow-x: auto !important;
  }
  .buyXGetYHeader {
    gap: 15px;
    button {
      margin: 0 !important;
    }
  }
}
