@import "../../../../../shared/style/Variables.scss";

.add-user-main-content {
  background-color: #f8f8fb !important;
  padding: 15px;
  .add-user-title {
    text-align: left;
    font: Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    opacity: 1;
  }
  .add-user-icon {
    opacity: 1;
    margin-top: 1rem;
  }
  .add-user-form {
    max-width: 60%;
    .ant-input-affix-wrapper {
      background-color: #f3f3f9;
      height: 3rem;
      border-radius: 8px;
    }
    .addUserbtn {
      width: 20%;
    }
    .ant-select-selector {
      // background-color:  #f3f3f9;
      height: 3rem;
      border-radius: 8px;
      padding: 0.5rem;
    }
    .address-text {
      border-radius: 8px;
      background-color: #fff !important;
      height: 7rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .addUserbtn {
    width: 50% !important;
  }
}

@media screen and (max-width: 480px) {
  .addRoleLeft {
    border: none !important;
  }
}
