@import "../shared/style/Variables.scss";
.otp-form-row {
  .otp-form-wrapper-column {
    padding: 30px;
    margin-left: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;

    .otp-form-wrapper {
      align-items: center;
      justify-content: center;

      .logo {
        margin-bottom: 30px;
      }

      .otp-title {
        font-family: Roboto;
        font-weight: bold;
        font-size: 40px;
        letter-spacing: 0;
      }
      .otp-text {
        font-family: Roboto;
        font-weight: regular;
        letter-spacing: 0;
        font-size: 18px;
        color: #a7a7a7;
        margin-bottom: 40px;
      }
      width: 400px;
      .form-logo {
        max-height: 45px;
        margin-bottom: 50px;
      }

      .form-text {
        color: gray;
        font-weight: 200;
        font-size: xx-small;
      }
      .otp-input {
        width: 400px;
        height: 60px;
        border: 1px solid #d0d0d2;
        border-radius: 8px;
        opacity: 1;
      }
      .reset-btn {
        width: 400px;
        height: 60px;
        color: white;
        background-color: #c71e21;
        margin: 0 !important;
      }
    }
  }
  .otp-text-column {
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .otp-text-wrapper {
      .otp-prop-image {
        height: 100vh;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and(max-width:1024px) {
  .otp-form-wrapper-column {
    padding: 40px;
    margin-left: 30px !important;
    .forgot-input {
      width: 300px !important;
    }
    .reset-btn {
      width: 300px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .otp-form-wrapper-column {
    .otp-form-wrapper {
      width: 300px !important;
    }
    border-top-left-radius: 50px;
    position: absolute !important;
    top: 30% !important;
    z-index: 1;
    background-color: white;
    width: 100% !important;
    margin: 0 !important;
    padding: 30px !important;
    padding-left: 50px !important;
    .otp-title {
      font-size: 24px !important;
    }
    .otp-text {
      font-size: 16px;
      margin-bottom: 20px !important;
    }
    .logo {
      padding-top: 20px;
      padding-left: 25%;
      margin-bottom: 20px !important;
      img {
        height: 50px;
      }
    }
    .otp-input {
      height: 50px !important;
    }
    .reset-btn {
      height: 50px !important;
    }
  }
  .otp-text-column {
    position: absolute !important;
    top: 0% !important;
    height: 50px !important;
    .otp-prop-image {
      height: 500px !important;
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .otp-form-row .otp-form-wrapper-column .otp-form-wrapper .otp-input {
    width: 100%;
  }
  .otp-form-wrapper-column {
    .otp-form-wrapper {
      width: 100% !important;
    }
   
    padding: 50px 30px !important ;
    .logo {
      margin: auto !important;
      padding: 0px !important;
      margin-bottom: 20px !important;
    }
    .otp-input {
width: 100% !important;
    }
    .reset-btn {
      width: 100% !important;
    }
 
  }
}
