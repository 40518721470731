@import "../../../shared/style/Variables.scss";

.addToCartBtn {
  background-color: $primary-color !important;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  height: 67px;
  width: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.addToCartBtn:hover,
.addToCartBtn:focus {
  background-color: $white !important;
  color: $primary-color !important;
}


@media screen and (max-width: 768px) {
  .addToCartBtn {
    height: 60px;
    width: 250px;
  }
}
