@import "../../../../../shared/style/Variables.scss";

.admin-page-title {
  font-size: 32px;
}

.dashboard-label {
  margin: 0;
  .ant-picker{
    height: 3.5rem;
    border-radius: 4px;
  }

  .ant-form-item-row {
    align-items: center;
  }
}
.status-form-order-summary{
  width:100%;
}

.dashboard-input {
  padding: 15px 20px;
  height: 100%;
  width: 90%;
  min-width: 198px !important;
  border-radius: 5px;
}

.dashboard-select {
  .ant-select-selector {
    min-width: 198px !important;
    padding: 12px 20px !important;
    height: 100% !important;
    border-radius: 5px !important;
  }
}

.dashboard-btn {
  min-width: 188px !important;
  
  padding: 15px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;


  .icon {
    color: $primary-color;
  }
}

.dashboard-date {
  min-width: 188px !important;
  padding: 15px 20px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;
}

.ant-table-thead .ant-table-cell {
  background-color: $primary-color !important;
  color: $white;
}

.ant-table.ant-table-middle {
  font-size: 16px;
}

.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td {
  padding: 15px 20px;
}

.ant-table-row:nth-child(odd) {
  background-color: #f7f7f7;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: $primary-color;
}

.ant-table-filter-trigger,
.ant-table-column-sorter {
  color: $white;
}

@media screen and (max-width: 1600px) {
  .dashboard-btn{
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .stock-summary-container {
    row-gap: 10px;
  }

  .dashboard-date {
    min-width: 100% !important;
    height: 100%;
  }

  .admin-page-title {
    font-size: 22px;
  }

  .dashboard-label {
    margin: 0;

    .ant-form-item-row {
      align-items: center;
    }
  }

  .dashboard-input {
    padding: 15px 20px;
    height: 100%;
    width: 90%;
    min-width: 100% !important;
    border-radius: 5px;
  }

  .dashboard-select {
    .ant-select-selector {
      min-width: 100% !important;
    }
  }

  .dashboard-btn {
    min-width: 100% !important;

    height: 100%;
  }
}

@media screen and (max-width:990px) {
  .dashboard-btn{
    margin-top: 5px;
  }
  
}
@media screen and (max-width: 480px) {
  .stock-summary-container {
    div {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .dashboard-btn {
    margin-top: 15px;
  }
}
