@import "../../../../shared/style/Variables.scss";

.sales-checkout-card-image {
  img {
    height: 151px;
    width: 256px;
  }
}
.sales-checkout-product-card {
  width: 100% !important;
}
.sales-checkout-page-card-quantity {
  justify-content: flex-start !important;
  gap: 20px !important;
  margin: 0 !important;
}
.sales-checkout-card-cancel {
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-end;
  padding-right: 1rem;
  .price {
    font-size: 24px;
    font-weight: bold;
    color: $primary-color;
  }
  button {
    border: 1px solid #d0d0d2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 8px !important;
    color: $primary-color;
  }
  .sales-checkout-delete {
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border: 1px solid #696969;
    margin-top: auto;
  }
}
.sales-checkout-input {
  margin-left: 8px;
  width: 3.2rem;
  border-radius: 4px;
  border: $black 1px solid;
}
.price {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .sales-checkout-card-image {
    img {
      height: 110px !important;
      width: 140px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .sales-checkout-delete {
    position: absolute;
    right: 10px;
  }
  .cart-product-card {
    padding-bottom: 15px;
    padding-top: 15px;
    flex-direction: column;
    gap: 5px;
    // .sales-checkout-card-image {
    //   order: 2 !important;
    // }
    .sales-checkout-card-cancel {
      // order: 4 !important;
      flex-direction: row;
      .price {
        font-size: 28px !important;
      }
    }
    .sales-checkout-card-details {
      .sku {
        justify-content: space-between !important;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

    .sales-checkout-card-cancel {
      .price {
        font-size: 18px;
        margin: 0;
      }
    }
  }
}
