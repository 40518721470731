@import "../../../../shared/style/Variables.scss";

.cart-detail-right-top {
  // width: 80%;
  display: flex;
  align-items: flex;
  // ga: 30px;
}
.cart-detail-right {
  width: inherit;
}
.cart-product-card {
  width: 80%;
  display: flex;
  align-items: flex;
  gap: 15px;
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid $primary-color;

  .image {
    flex: 1;
    cursor: pointer;

    img {
      height: auto;
      width: 216px;
      border-radius: 8px;
      object-fit: contain !important;
      border: 0.5px solid $border-color;
    }
  }
}
.details {
  flex: 3;

  p {
    padding: 0 !important;
  }

  .title {
    font-size: 20px !important;
    font-weight: normal;
    margin-bottom: 10px;
    line-height: 1.2rem;
    cursor: pointer;
  }

  .sku {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #808080;
  }

  .price {
    font-size: 24px;
    font-weight: bold;
    color: $primary-color;
  }
}

.quantity {
  flex: 1;
  display: flex;
  // justify-content: flex-end;
  gap: 10px;
  margin-left: 10px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .minus,
  .plus,
  .value {
    height: 48px;
    width: 48px;
    text-align: center;
    border-radius: 7px;
    font-size: 1.4rem;
  }
}

.cancel {
  flex: 1.2;
  display: flex;
  justify-content: flex-end;
  color: $primary-color;

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 2.4rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 1440px) {
  .cart-product-card {
    width: 95%;
    .minus,
    .plus,
    .value {
      height: 40px !important;
      width: 40px !important;
    }
  }
}
// @media screen and (max-width: 1250px) {
//   .cart-product-card {
//    .image{
//     width: 30%;
//    }
//   }
// }

@media screen and (max-width: 500px) {
  .cart-product-card {
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
    flex-direction: column;

    .image {
      order: 1;
    }
    .quantity {
      button,
      input {
        height: 2rem !important;
        width: 2rem !important;
      }
    }
    .cart-detail-right {
      order: 2;
    }
    .cart-detail-right-top {
      order: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
    }
    .cart-detail-right-bottom {
      order: 2;
    }
    .product-detail-offer-text {
      font-size: 0.8rem;
      .icon {
        height: 1rem;
        width: 1rem;
      }
    }
    .details {
      order: 1;
      padding-top: 15px;

      .title {
        font-size: 16px !important;
        order: 1;
      }

      .sku {
        justify-content: start;

        p {
          margin: 0;
          padding: 0;
          line-height: 1.2rem;
        }

        font-size: 12px;
      }

      .price {
        font-size: 24px;
        margin: 15px 0px;
      }
    }

    .quantity {
      order: 2;
      // padding-top: 50px;
      justify-content: start;

      .minus,
      .plus,
      .value {
        font-size: 16px;
        height: 36px;
        width: 36px;
      }
    }

    .cancel {
      order: 3;

      .css-i4bv87-MuiSvgIcon-root {
        font-size: 2rem;
      }
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}

.free-input {
  justify-content: flex-end !important;
  padding-right: 1.7rem;
  input {
    border: 1px solid;
    background-color: #b8b4b473;
  }
}
.set-text {
  color: $primary-color;
}
.loader-main {
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.cart-collapse > div > div {
  color: $primary-color !important;
  font-weight: bold !important;
}
