@import "../../../../../shared/style/Variables.scss";
.user-management-main-content{
    background-color: #f8f8fb !important;
    padding: 15px;

    .manage-products-title {
        text-align: left;
        font: Roboto;
        font-weight: bold;
        letter-spacing: 0px;
        color: #222222;
        text-transform: uppercase;
        opacity: 1;
        padding: 10px 0;
        
      }

      .product-btn{
        background: #C71E21 0% 0% no-repeat padding-box;
        margin: 1rem 0;
        border-radius: 8px;
        opacity: 1;
        height: 3rem;
        text-align: left;
        font: normal normal 14px/17px Roboto;
        letter-spacing: 0.1px;
        color: #FFFFFF;
        opacity: 1;
    }
    
    .ant-table-filter-column {
        justify-content: center !important;
        // flex: 1 0;
      } 
      .ant-table-column-title {
      flex: none;
      text-align: left;
      font: normal normal  16px/19px Roboto !important;
      letter-spacing: 1.16px;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
      // text-decoration: none !important;
      }
    
       .ant-table tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        white-space: normal;
      }    


    .anticon svg{
        height: 0.8rem !important;
        width: 0.8rem !important;
    }
   
    .action-btn{
        margin: 0 0.5rem ;
    }
    .ant-switch-checked{
        background-color: #1890ff;
    }
    
}