.new-offer-input {
  //   width: 40rem;
  .offer-date {
    border-radius: 10px;
    background-color: #f3f3f9;
  }
}
.new-offer-btn {
  margin: 0;
  margin-top: 15px;
  // margin-left: 11rem !important ;
}

.edit-offer-modal {
  width: 40% !important;
}
