@import "../../../../../shared/style/Variables.scss";

.confirmation-container {
  border-bottom: 1px solid $primary-color;
  margin-bottom: 40px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .anticon {
      svg {
        height: 100px !important;
        width: 100px !important;
      }
      color: $primary-color !important;
      margin-bottom: 0px !important;
    }

    .ant-result-title {
      font-size: 48px !important;
      font-weight: bold !important;
      margin-bottom: 15px;
    }

    .ant-result-subtitle {
      font-size: 44px !important;
      line-height: 1.6rem;

      p {
        margin-bottom: 20px;
      }

      p:last-child {
        font-size: 24px;
      }
    }

    .ant-result-extra {
      .theme-btn1 {
        cursor: pointer;
        display: flex;
        justify-content: center;
        border: 1px solid $border-color;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}
.summary-details {
  padding: 0 33px 33px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-color: $primary-color;
  border-right: 1px solid $primary-color;

  p {
    font-size: 21px;
    line-height: 1.6rem;
    margin-bottom: 10px;
  }
  .ant-divider-horizontal {
    margin: 15px 0 !important;
  }

  .title {
    padding: 0;
    font-size: 24px;
    font-weight: medium;
    margin-bottom: 20px;
    color: #383838;
  }

  .details {
    width: 100%;
    display: flex;
    justify-content: space-between;

    p {
      color: #5d5d5d;
    }
    .price {
      margin-bottom: 30px;
    }
  }

  .details1 {
    p {
      font-weight: normal;
    }
  }

  .details2 {
    p {
      font-weight: medium;
    }

    .totalPrice {
      color: $primary-color;
      font-weight: 500 !important;
    }
  }
}
.delivery-details {
  border: none;
  .name {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .address,
  .phone {
    font-size: 21px;
    color: #5d5d5d;
    line-height: 1.6rem;
    margin-bottom: 15px;
  }
  .time {
    font-size: 21px;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .confirmation-container {
    div {
      .anticon {
        font-size: 70px;
      }

      .ant-result-title {
        font-size: 32px !important;
      }

      .ant-result-subtitle {
        font-size: 32px !important;
      }
    }
  }

  .summary-details {
    border: none;
    padding: 0px;
  }
  .delivery-details {
    border: none;
    border-top: 1px solid $primary-color;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-top: 25px;
  }
}
