@import "../../../../../shared/style/Variables.scss";

.order-details-main-layout {
  background-color: #f8f8fb !important;
  padding: 15px;
  .order-details-title {
    text-align: left;
    font: Roboto;
    word-break: break-all;
    font-weight: bold;
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    opacity: 1;
  }
  .order-details-icon {
    opacity: 1;
    margin-top: 1rem;
  }
  .order-details-inner-title {
    //  text-align: left;
    font-weight: bold;
    font-size: 40px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
  }

  .order-status {
    width: 5rem;
    padding: 0.5rem;
    display: flex;
    flex: 1 1;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    .order-dot {
      height: 0.5rem;
      width: 0.5rem;
      background-color: #4ed92d;
      border-radius: 50%;
    }
    .order-status-new {
      background-color: #efffea;
    }
    .order-status-delivered {
      background-color: #fffdea;
    }
  }
  .order-generate-btn {
    border-radius: 5px;
    padding: 0.5rem 1.1rem;
    display: flex;
    align-items: center;
    height: 3rem;
    // width: 9rem;
  }
  .order-details-content-row {
    padding: 1rem 0 !important;
  }
  .order-details-div {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
    opacity: 0.6;
    padding: 1rem 2rem;
    height: 100% !important;
    margin: 10px 0;

    .order-detail-div-title {
      text-align: left;
      font-size: 16px;
      font-family: Roboto;
      letter-spacing: 1.16px;
      color: #131523;
      text-transform: uppercase;
      opacity: 0.5;
      padding-top: 1rem;
      display: flex;
      gap: 5px;
      .anticon svg {
        height: 1.3rem !important;
        width: 1.3rem !important;
        font-weight: bold;
      }
    }
    .order-detail-div-content {
      text-align: left;
      font-size: 18px;
      font-family: Roboto;
      font-weight: 900;
      letter-spacing: 0px;
      color: black;
      opacity: 1;
      padding-top: 0.1rem;
      padding-bottom: 1rem;
      padding-left: 1.6rem;
    }
    .order-detail-name {
      text-align: left;
      font: normal normal bold 18px/22px Roboto;
      letter-spacing: 0px;
      color: #222222;
      opacity: 1;
      padding-top: 0.7rem;
      padding-left: 1.6rem;
      padding-bottom: 0.7rem;
    }
    .order-detail-div-address {
      padding-left: 1.6rem;
      text-align: left;
      font-size: 20px;
      word-break: break-all;

      font-family: Roboto;
      letter-spacing: 0px;
      color: #959595;
      opacity: 1;
      padding-bottom: 0.7rem;
    }
    .order-detail-phone {
      text-align: left;
      font: normal normal normal 18px/22px Roboto;
      letter-spacing: 0px;
      color: #959595;
      opacity: 1;
      padding-left: 1.6rem;
    }
  }

  .ant-table-filter-column {
    justify-content: flex-start !important;
    // flex: 1 0;
  }
  .ant-table-column-title {
    flex: none;
    // text-align: left;
    font: normal normal 16px/19px Roboto !important;
    letter-spacing: 1.16px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    text-decoration: none !important;
  }
  .ant-table tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    white-space: normal;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .price-div {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 7px;
    opacity: 0.5;
    width: 8rem;
    margin: auto;
    .ant-input {
      background-color: white;
      border-left: none;
      border-radius: 7px;
      padding: 5px;
    }
    .dollar-price {
      background: #ededed;
      border: 1px solid #707070;
      opacity: 0.5;
      padding: 0.1rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem !important;
    }
    //  .price-number{
    // // padding: 0 0.5rem;

    //  }
  }
  .product-photo {
    height: 4.5rem;
    width: 4.5rem;
  }
  .quantityOrderDetail {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 7rem;
    margin: auto;
    .quantity-value {
      text-align: left;
      font: normal normal 18px/22px Roboto;
      letter-spacing: 0px;

      opacity: 1;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .minusOrderDetail,
    .plusOrderDetail,
    .valueOrderDetail {
      height: 2rem;
      width: 2rem;
      text-align: center;
      border-radius: 7px;
      font-size: 1.4rem;
    }
    .minusOrderDetail {
      background-color: #d0d0d2;
      color: #a7a7a7;
    }
    .plusOrderDetail {
      background-color: #c71e21;
      color: white;
    }
  }
}

.lpoModalHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px !important;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
  p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  p:nth-child(1) {
    color: #131523;
    opacity: 0.5;
    font-size: 14px;
  }
  p:last-child {
    color: #222222;
  }
}

.generate-lpo-modal {
  z-index: 999999999 !important;
  width: 50% !important;
}

@media screen and (max-width: 1440px) {
  .generate-lpo-modal {
    width: 60% !important;
  }
  .order-details-inner-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .order-details-main-layout .order-details-inner-title {
    font-size: 28px;
  }
  .order-detail-div-title {
    font-size: 16px;
  }
  .order-detail-div-content {
    font-size: 14px;
  }
  .order-details-div {
    padding: 25px !important;
    height: auto !important;
    margin: 10px 0;
  }
  .btn-small {
    height: 2rem !important;
    width: 2rem !important;
  }
}

@media screen and (max-width: 1280px) {
  .generate-lpo-modal {
    width: 90% !important;
  }
}

@media screen and (max-width: 768px) {
  .order-details-div {
    height: auto !important;
    padding: 10px !important;
    margin: 10px 0;
  }
  .order-detail-col {
    padding: 10px;
    justify-content: flex-start !important;
  }
  .generate-lpo-modal {
    width: 80% !important;
  }
  .order-details-inner-title {
    font-size: 24px !important;
  }
  .order-detail-col {
    padding: 10px;
    justify-content: flex-start !important;
  }
  .lpoModalHeader {
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 0;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
    }
    p:nth-child(1) {
      color: #131523;
      opacity: 0.5;
      font-size: 14px;
    }
    p:last-child {
      color: #222222;
    }
  }

  .order-details-main-layout .order-details-div {
    padding: 20px !important;
    .order-detail-div-title {
      padding-top: 0;
    }
    .order-detail-div-content {
      font-size: 16px;
    }
    .order-detail-div-address {
      font-size: 16px;
    }
    .order-detail-phone {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 500px) {
  .generate-lpo-modal {
    width: 90% !important;
  }
}
@media screen and (max-width: 480px) {
  .order-details-main-layout {
    padding: 0;
  }
  .order-details-inner-title {
    font-size: 16px !important;
  }

  .order-details-main-layout .order-details-div {
    height: auto !important;
    padding: 20px !important;
    .order-detail-div-title {
      padding-top: 0;
    }
    .order-detail-div-content {
      font-size: 16px;
    }
    .order-detail-div-address {
      font-size: 16px;
    }
    .order-detail-phone {
      font-size: 16px;
    }
  }

  .order-detail-col {
    padding: 10px;
    justify-content: flex-start !important;
  }
}

.order-details-main-layout .order-details-content-row {
  padding: 0;
}
.orderBadge {
  padding: 10px 15px;
  height: fit-content;
  background-color: #d5fdc9;
  border-radius: 100vw;
  .ant-badge-status-text {
    font-size: 14px;
    font-weight: 600;
  }
}
.order-details-main-layout .price-div .ant-input {
  background-color: white;
  border-left: none;
  border-color: white !important;
  box-shadow: none !important;
  border-radius: 7px;
}

.adminOrderDetailsSelect {
  .ant-select-selector {
    padding: 10px 15px !important;
    height: 100% !important;
    border-radius: 5px !important;
  }
}
