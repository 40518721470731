@import "../../../../../shared/style/Variables.scss";
// p{
// line-height: 1.6rem;

// }

.contentPadding {
  padding: 0px 5rem;
}

.address-bg-color,
.address-main-content-layout {
  background-color: $white;
}

.ant-breadcrumb-link,
.address-breadcrumb-active {
  font-size: 16px;
}

.address-breadcrumb-active {
  color: $primary-color;
  font-weight: 600;
}

.address-main-content-layout {
  padding: 40px 0;

  .title {
    height: 59px;
    text-align: center;
    font: normal normal bold 48px/58px Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    margin: auto;
    margin-bottom: 40px;
  }
}

.img-add-address {
  cursor: pointer;
}

.add-address-box {
  border-radius: 10px;
  border-style: solid;
  border-color: $primary-color;
  min-height: 280px;
  margin: 10px;
  display: flex;
  justify-content: center;
  padding-top: 80px;

  p {
    padding-top: 10px;
    color: #5d5d5d;
  }
}

.address-box {
  border-radius: 10px;
  border-style: solid;
  border-color: $primary-color;
  min-height: 280px;
  margin: 10px;
  padding: 25px;

  .address-box-title {
    font-weight: bold;
    font-size: 20px;
    font-family: Roboto;
  }

  .address-box-main-details {
    min-height: 120px;
    max-width: 80%;
  }

  p {
    color: #5d5d5d;
    font-size: 18px;
  }

  .divide {
    margin-top: 15px;

    margin-bottom: 15px;
    border: 1px dashed #8e8e8e;
    opacity: 0.5;
  }

  .address-action {
    border: 0;
    color: #000000;

    font-size: 20px;
    outline: none;
  }
}

@media screen and (max-width: 768px) {
  .address-main-content-layout .title {
    font-size: 24px;
    width: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modalLabelsSelect {
  .ant-select-arrow {
    position: absolute !important;
    left: 4% !important;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      margin-left: 10% !important;
    }
    .ant-select-selection-search {
      display: flex;
      input {
        height: 100% !important;
        margin-left: 10% !important;
        text-indent: 10px !important;
      }
    }
    .ant-select-selection-placeholder {
      padding-left: 10% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .address-main-content-layout {
    padding: 15px 0;
    .title {
      margin-bottom: 20px;
    }
  }
  .add-address-box {
    min-height: 200px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-address {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 60px;
      }
      p {
        margin: 0;
      }
    }
  }
  .address-box {
    min-height: fit-content;
    .address-box-title {
      font-size: 18px;
    }
    .address-box-main-details {
      max-width: 100%;
      min-height: fit-content;
      p {
        font-size: 16px;
      }
    }
  }
  .address-box .divide {
    margin: 5px 0;
  }
  .address-action {
    span {
      font-size: 16px;
    }
  }
}
