@import "../../../../../shared/style/Variables.scss";
.cursor-pointer {
  cursor: pointer;
}
.edit-product-main-content {
  background-color: #f8f8fb !important;
  padding: 15px;
  .main-title {
    text-align: left;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    opacity: 1;
  }
  .edir-product-icon {
    opacity: 1;
    margin-top: 1rem;
    color: #000000;
  }

  .edit-product-title {
    text-align: left;
    font: normal normal bold 24px/29px Roboto;
    margin-bottom: 1rem;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    margin-top: 1rem;
    svg {
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
  }
  .edit-product-switch {
    Button {
      height: 2rem;
      width: 6rem;
      // .ant-switch-handle{

      // }
    }
  }
  .dealer-detail-title {
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .edit-product-form {
    .ant-col {
      padding-left: 0 !important;
    }
    .ant-input-disabled {
      //   font-weight: bold;
      color: #000000;
    }

    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 3rem;
    .upload-form {
      margin-top: 3rem;
      height: 4rem;
      width: 20rem;
      background-color: #f3f3f9;
      // border: solid 1px;
      border-radius: 8px;
      .ant-input {
        font-size: 1rem;
        font-weight: bold;
        color: #000000;
      }
    }
    .upload-form-img {
      border: 1px solid #d0d0d2;
      height: 4rem;
      width: 30rem;
      border-radius: 8px;
      background-color: #f3f3f9;
      padding: 1rem;
      margin-top: 2.5rem;
      display: flex;
      justify-content: space-between;
      img {
        height: 2rem;
        width: 2rem;
      }
    }

    .anticon svg {
      color: #c71e21;
      height: 1.3rem !important;
      width: 1.3rem !important;
    }

    .ant-form-item-label {
      text-align: left;
      font: normal normal normal 18px/22px Roboto;
      letter-spacing: 0px;
      color: #383838;
      margin-bottom: 0.5rem;
    }
    .ant-input-affix-wrapper {
      background-color: #f3f3f9;
      height: 3rem;
      border: 1px solid #d0d0d2;
      border-radius: 8px;
    }

    .address-text {
      height: 12rem;
    }

    .edit-product-address-btn {
      border: 1px solid #c71e21;
      border-radius: 8px;
      text-align: left;
      font: normal normal 18px/22px Roboto;
      letter-spacing: 0.13px;
      color: #000000;
      margin-top: 2rem;
      padding: 1rem 2rem;
      height: 4rem;
      width: 14rem;
      background-color: #f3f3f9;
    }

    .ant-select-selector {
      background-color: #f3f3f9;
      height: 3rem;
      border: 1px solid #d0d0d2;
      border-radius: 8px;
      padding: 0.5rem;
    }

    .edit-product-btn {
      border: 1px solid #c71e21;
      border-radius: 8px;
      text-align: left;
      font: normal normal 18px/22px Roboto;
      letter-spacing: 0.13px;
      color: white;
      margin-top: 2rem;
      padding: 1rem 4rem;
      height: 4rem;
      width: 14rem;
      background-color: #c71e21;
    }
  }
}
.ck-editor__editable {
  min-height: 350px !important;
}

.editor-quill {
  background-color: #fff;
  background-size: contain;

  &.disable {
    background-color: #f5f5f5;
    cursor: not-allowed;

    .ql-toolbar {
      pointer-events: none;
    }

    .ql-container {
      color: rgba(0, 0, 0, 0.25);
    }

    * {
      cursor: not-allowed;
    }
  }
}

.editProductRight {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

@media screen and (max-width: 1024px) {
  .edit-product-main-content .dealer-detail-title {
    margin: 0;
    padding: 0 15px;
  }
  .edit-product-main-content .edit-product-form {
    padding: 0 15px;
    margin: 0;
  }
  .edit-product-main-content .edit-product-form .upload-form {
    margin-top: 1rem;
    height: 3rem;
    width: 80%;
  }
  .edit-product-main-content .edit-product-form .upload-form-img {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    gap: 0;
    justify-content: space-between;
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .edit-product-main-content {
    padding: 0;
  }
  .edit-product-main-content .dealer-detail-title {
    margin: 0;
    padding: 0;
  }
  .edit-product-main-content .edit-product-form {
    padding: 0;
    margin: 0;
  }
  .edit-product-main-content .edit-product-form .upload-form {
    margin-top: 1rem;
    height: 3rem;
    width: 100%;
  }
  .editProductRight {
    gap: 10px;
  }
  .edit-product-main-content .edit-product-form .upload-form-img {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    gap: 0;
    justify-content: space-between;
    position: relative;
  }
}
