.sales-table-download {
  background-color: #fff !important;
  border: 1px solid #034ea2 !important;
  color: #5a607f !important;
  .icon {
    color: #c71e21;
  }
}
.sales-dealer-list-table .ant-table-column-title {
  text-align: center;
  font: normal normal 14px/17px Roboto;
  letter-spacing: 1.02px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
.sales-dealer-list-table{
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 10px !important;
    text-align: center;
    align-items: center;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;
}
.anticon svg{
  height: 0.8rem !important;
  width: 0.8rem !important;
}
.ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle .ant-table-tbody > tr > td {
  /* padding: 15px 20px; */
  text-align: center;
}
}