@import "../../../../../shared/style/Variables.scss";

.product-main-content {
  background-color: white;
  .sider-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.wrapper {
  background-color: white;

  .ant-row {
    margin: 0;
  }
}

.products-breadcrumb {
  margin: 30px 0;
}
.filter {
  background-color: white;
  height: 100%;
  padding: 0 15px;
  text-align: center;
  .filter-btn-div {
    display: none;

    .filter-btn {
      background-color: $medium-gray;
      // margin :10px;
    }

    @media only screen and (max-width: 600px) {
      display: flex;
      gap: 10px;
      flex-direction: row-reverse;
    }
  }
}

@media screen and (min-width: 768px) {
  .filter {
    display: none;
  }
}

.bread-crumb {
  background-color: white;
  padding: 0 30px;
}

.ant-breadcrumb-link,
.products-breadcrumb-active {
  font-size: 16px;
}

.products-breadcrumb-active {
  color: $primary-color;
  font-weight: 600;
}

.ant-breadcrumb li {
  display: flex;
  align-items: center;
}

.sider-div {
  // margin: 20px;
  margin-bottom: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  opacity: 1;
  background-color: white;
  padding: 20px;

  min-width: 100px;

  .addToCartBtn {
    // margin: 0 30px;
    font-size: 20px;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.product-sider-collapsed {
  width: 0 !important;
  display: none;
  border: 0;

  .sider-div {
    padding: 0 !important;
    font-size: 0;
  }
}

.sider-main {
  background-color: white;
}

.sider-title {
  padding: 15px;
  margin: 0;
  text-align: left;
  font: normal normal bold 18px/22px Roboto;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.sider-txt {
  text-align: left;
  font-size: 16px;
  margin: 0;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #383838;
}

.sider-number {
  text-align: left;
  font: normal normal normal 18px/22px Roboto;
  letter-spacing: 0px;
  color: #a7a7a7;
}

.sider-checkbox {
  overflow-y: auto;
  max-height: 300px;
  padding: 10px 15px;

  .ant-checkbox-inner {
    // border: 1px solid #c4c4c4 !important;
    border-radius: 4px;

    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d0d0d2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c71e21;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c71e21;
  }
}

.view {
  background-color: white !important;
  padding: 0 0 0 60px;
  margin-bottom: 5px !important;
}

.view-page {
  text-align: left;
  font: normal normal medium 18px/22px Roboto;
  letter-spacing: 0px;
  opacity: 1;

  .view-page-number {
    font-weight: bold;
  }
}

.filterDropdownContainer {
  display: flex;
  justify-content: end;
}

.filter-dropdown {
  right: 30px;

  p {
    color: $medium-gray;
  }

  a {
    color: $medium-gray;
  }

  .dropdown-title {
    padding: 10px;
    margin: 10px 0;
    color: black;
    font-weight: bold;
    letter-spacing: 0;
  }

  color: $medium-gray;
}

.divider {
  color: #d9d9d9;
}

.ant-slider-track {
  background-color: #c71e21;
}

.ant-slider-track ::selection {
  background-color: #c71e21;
  background: #c71e21;
}

.ant-slider-handle {
  border-color: #c71e21;
}

.content {
  background-color: white;
}

.products-display {
  padding: 0 0 0 40px;
  // justify-content: start;
}

.reset-btn {
  background-color: white;
  color: #c71e21;
  border: #c71e21 1px;
  font-size: 18px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.sider-row {
  padding: 5px 0px;
}

.product-card {
  position: relative;
  // margin: 30px 0;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 30px;
  opacity: 1;
  width: 31%;

  .offer-line {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $primary-color;
    border-bottom-left-radius: 100vw;
    // border-bottom-right-radius: 15px;
    .product-detail-offer-text {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 30px;
      padding-right: 15px;
      color: $white;
      margin: 0;
    }
  }

  //    .out-of-stock{
  //     width: 176px;
  // height: 50px;
  // background-color: #F85053;
  // border-radius: 5px;
  // position: absolute;
  // top:150px;
  // left: 100px;
  //    }
  .product-stock {
    text-align: left;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #6cc71e;
    opacity: 1;
  }

  .product-sku {
    text-align: left;
    letter-spacing: 0px;
    color: #383838 !important;
    opacity: 1;
  }

  .product-card-image {
    width: 100%;

    img {
      border-radius: 21px;
      width: 100%;
      height: 380px;
      object-fit: contain;
    }
  }
}
.product-card-search {
  position: relative;
  margin-bottom: 20px;
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 30px;
  opacity: 1;
  width: 24%;

  .offer-line {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $primary-color;
    border-bottom-left-radius: 100vw;
    // border-bottom-right-radius: 15px;
    .product-detail-offer-text {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 30px;
      padding-right: 15px;
      color: $white;
      margin: 0;
    }
  }

  //    .out-of-stock{
  //     width: 176px;
  // height: 50px;
  // background-color: #F85053;
  // border-radius: 5px;
  // position: absolute;
  // top:150px;
  // left: 100px;
  //    }
  .product-stock {
    text-align: left;
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #6cc71e;
    opacity: 1;
  }

  .product-sku {
    text-align: left;
    letter-spacing: 0px;
    color: #383838 !important;
    opacity: 1;
  }

  .product-card-image {
    width: 100%;

    img {
      border-radius: 21px;
      width: 100%;
      height: 380px;
      object-fit: contain;
    }
  }
}

.product-details {
  .title {
    padding: 15px 0 20px;
    font-size: 24px;
    margin: 0;
    text-align: left;
    // min-height: 110px;
  }

  .price {
    font-size: 24px !important;
    color: $primary-color;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }

  .inventory-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      p:nth-child(1) {
        font-size: 16px;
        color: #383838;
        margin-bottom: 2px;
      }

      p:nth-child(2) {
        font-size: 18px;
      }
    }
  }
}

.product-card,
.product-card-search {
  .AddToCartBtnContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    button {
      padding: 2px;
      color: white;
    }

    .wishlistBtn {
      height: 67px;
      width: 67px;
      border: 1px solid #034ea2 !important;
      border-radius: 11px;
      color: #034ea2;
    }
  }
}

@media screen and (max-width: 768px) {
  .photo {
    max-width: 100%;
  }

  .product-image {
    img {
      width: 100%;
    }
  }

  .product-details {
    .title {
      font-size: 18px;
    }

    .price {
      font-size: 22px !important;
    }

    .inventory-info {
      div {
        p:nth-child(1) {
          font-size: 14px;
          color: #383838;
          margin-bottom: 2px;
        }

        p:nth-child(2) {
          font-size: 14px;
        }
      }
    }

    .AddToCartBtnContainer {
      .wishlistBtn {
        height: 55px;
        width: 55px;
        border: 1px solid #034ea2 !important;
        border-radius: 11px;
        color: #034ea2;
      }
    }
  }
}

.disabled {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 400px;
  width: 330px;
  // top: 0%;
}

@media screen and (max-width: 1440px) {
  // .contentPadding{
  //     padding :3rem !important;
  // }
  .sider-div {
    .addToCartBtn {
      margin-top: 30px !important;
      // margin-left: 18px !important;
      // width: 220px !important;
    }

    // .reset-btn {
    //   // margin-top: 30px !important;
    //   // margin-left: 18px !important;
    //   // width: 220px !important;
    // }
  }

  .product-card,
  .product-card-search {
    margin-top: 0px !important;
    height: 550px !important;
    width: 30%;
    padding: 10px !important;
    border-radius: 15px !important;

    .title {
      font-size: 20px !important;
    }

    .product-card-image {
      width: 100%;

      img {
        border-radius: 15px;
        width: 100%;
        height: 252px !important;
        object-fit: contain;
      }
    }

    .AddToCartBtnContainer {
      height: 20% !important;
      gap: 5px !important;
      grid-gap: 5px !important;
      margin-top: 20px !important;

      .addToCartBtn {
        width: 290px !important;
        height: 50% !important;
      }

      .wishlistBtn {
        width: 70px !important;
        height: 50% !important;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .product-card,
  .product-card-search {
    width: 47%;
    .product-card-image {
      img {
        height: 300px !important;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .product-card.product-card-search {
    justify-content: flex-start;
    gap: 10px;
    width: 46%;
  }
}

@media screen and (max-width: 992px) {
  .product-card.product-card-search {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .product-card {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .sortDropdown {
    min-width: fit-content !important;
  }
  .products-breadcrumb {
    margin: 15px 0;
  }
  .products-display,
  .view {
    padding: 0;
  }
  .view-page {
    margin-bottom: 5px !important;
  }
  .filterDropdownContainer {
    justify-content: start;
  }
  .product-details {
    .title {
      min-height: 100%;
    }
  }

  .products-display {
    justify-content: space-between;
  }

  .sider-div {
    min-width: 0 !important;
  }

  .product-card {
    height: 100% !important;
    width: 49% !important;
    margin: 0 !important;
    border-radius: 15px !important;

    .product-card-image {
      width: 100%;

      img {
        height: 190px !important;
      }
    }

    .product-details {
      .title {
        font-size: 16px !important;
        padding-bottom: 10px !important ;
      }
      .price {
        font-size: 19px !important;
        margin-bottom: 10px;
      }

      .inventory-info {
        div {
          .product-sku {
            font-size: 12px !important;
          }
        }
      }
    }

    .AddToCartBtnContainer {
      height: 20% !important;
      margin-top: 10px !important;

      .addToCartBtn {
        padding: 8px 10px;
        font-size: 12px !important;
        width: 100% !important;

        // height: 50% !important;
        .icon {
          font-size: 14px !important;
        }
      }

      .wishlistBtn {
        padding: 5px 8px;
        width: fit-content !important;

        .icon {
          svg {
            height: 14px !important;
            width: 14px !important;
            // font-size: 14px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .product-card {
    width: 100% !important;

    .product-card-image {
      img {
        height: 250px !important;
      }
    }
  }
}

.ant-dropdown-menu {
  padding: 0;
}

.sortByItem {
  flex: auto;

  span {
    padding: 10px;
    border-bottom: 1px solid grey;
  }
}

.sortDropdown {
  min-width: 178px;
  .ant-select-selector,
  .ant-select-selector:focus-within,
  .ant-select-selector:focus {
    border: none !important;
    outline: none !important;
    color: black !important;
  }
}
.overflow {
  overflow: hidden;
}
