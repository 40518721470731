.searchcard {
  padding: 1px;
  .photo {
    padding: 0px;
  }
  .searchcard-details {
    padding-top: 20px;
    text-align: left;
    font: normal normal normal 16px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }
  .searchcard-price {
    text-align: left;
    font: normal normal 22px/27px Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    color: #c71e21;
    opacity: 1;
    padding-top: 5px;
  }
  .anticon svg {
    color: #c71e21;

    height: 5rem !important;
    width: 2rem !important;
  }
}
.search-card-image-height {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
