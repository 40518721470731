@import "../../../../../shared/style/Variables.scss";

.manage-products-main-content {
  background-color: #f8f8fb !important;
  padding: 15px;
  min-height: 95vh !important;

  .manage-products-title {
    font-size: 1.4em;
    text-align: left;
    font: Roboto;
    // font-weight: bold;
    letter-spacing: 0px;
    color: #222222;
    // text-transform: uppercase;
    opacity: 1;
    padding: 10px 0;
  }

  .ant-table-filter-column {
    justify-content: center !important;
    // flex: 1 0;
  }

  .ant-table-column-title {
    text-align: center;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 1.02px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }

  .ant-table tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    white-space: normal;
  }

  .product-btn {
    background: #c71e21 0% 0% no-repeat padding-box;
    margin: 1rem 0;
    border-radius: 8px;
    opacity: 1;
    height: 3rem;
    text-align: left;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #ffffff;
    opacity: 1;
  }

  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    padding: 10px !important;
    text-align: center;
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 10px !important;
    text-align: center;
    align-items: center;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;
  }

  .productdetailCard-title {
    text-align: left;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;

    p {
      margin: 3px 0 0 0;
      line-height: 1;
      color: $secondary-color;
      font-size: 12px;
    }
  }

  .productcolor-div {
    height: 1rem;
    width: 1rem;
    //background: #DEC715 0% 0% no-repeat padding-box;
    margin-left: 1.5rem;
    border: 1px solid #b7a93e;
    opacity: 1;
    border-radius: 50%;
  }

  .edit-action-btn {
    background: #f1ffea 0% 0% no-repeat padding-box;
    border: 1px solid #22a203;
    opacity: 1;
    padding: 0.2rem;
    margin: 0.5rem;
  }

  .delete-action-btn {
    background: #ffe3de 0% 0% no-repeat padding-box;
    border: 1px solid #e81c01;
    opacity: 1;
    padding: 0.2rem;
    margin: 0.5rem;
  }

  .anticon svg {
    height: 0.8rem !important;
    width: 0.8rem !important;
  }
  .photo {
    max-width: 50px;
    max-height: 50px;
  }
}
