@import "../../../../../shared/style/Variables.scss";

.sales-search {
  padding: 15px 20px !important;
  border-radius: 14px !important;
  border: 1px solid #034ea2 !important;
}
.border-gap {
  border-right: 1px solid #034ea2;
}
.sales-table-search {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 30px;
  .sales-search {
    padding: 15px 20px;
    border-radius: 14px;
    border: 1px solid #034ea2;
  }
}
.sales-manage-order-table {
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 10px !important;
    text-align: center;
    align-items: center;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;
  }
  .anticon svg {
    height: 0.8rem !important;
    width: 0.8rem !important;
  }
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    /* padding: 15px 20px; */
    text-align: center;
  }
}
.sales-table-button {
  width: fit-content !important;
  border-radius: 14px;
  padding: 15px 20px !important;
}

.sales-table-button-container {
  display: flex;
  // justify-content: end;
  border-left: 1px solid #034ea2;
}
.popup {
  .ant-btn {
    background-color: $primary-color;
    color: white;
    border-radius: 4px;
  }
}
@media screen and (max-width: 800px) {
  .sales-table-button {
    width: 70% !important;
    height: 40%;
    margin-left: 20px;
    // padding: 10px !important;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .sales-table-button-container {
    justify-content: center;
    margin: 20px 0px;
  }
  .sales-table-button {
    width: 70% !important;
    height: 40%;
    margin-left: 20px;
    // padding: 10px !important;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .sales-table-search {
    border: none;
    margin: 0;
    padding: 0;
  }
}
