@import "../../../../../shared/style/Variables.scss";

.wishlist-topbar {
  background-color: $bg-color;
  display: flex;
  padding: 20px 40px;
  justify-content: space-around;

  p {
    font-size: 24px;
    font-weight: 500;
    margin: 0;

    span {
      color: $primary-color;
      margin-left: 15px;
    }
  }

  .clear-all {
    cursor: pointer;
  }
}

.wishlist-search {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  background-color: $bg-color;
  gap: 10px;

  input,
  input:focus {
    outline: none;
    border: none;
    font-size: 18px;
    background-color: transparent;
  }
}
.no-prod {
  margin-top: 30px;
  padding: 20px;
  font-size: 24px;
  text-align: center;
}

.wishlistCardContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;
}

@media screen and (max-width: 1280px) {
  .wishlistCardContainer {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .wishlist-topbar {
    p {
      font-size: 16px;
    }
  }
  .wishlistCardContainer {
    justify-content: center;
  }
  .wishlist-search {
    position: relative;
  }
}
@media screen and (max-width: 480px) {
  .no-prod {
    font-size: 18px;
  }
  .wishlistCardContainer {
    padding: 0 15px;
  }
}
