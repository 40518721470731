.dealerListTable {
  .ant-table-thead .ant-table-cell {
    text-align: left;
  }
}

tbody {
  .ant-table-measure-row {
    display: none;
  }
}

.product-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stockLabelModalTitle {
  span {
    display: inline-block;
    margin-top: 5px;
    font-size: 1.2rem;
  }
}

.stockLabelBtnContainer {
  width: 50%;
}

.stockLabelActionContainer {
  margin-bottom: 15px;
  overflow: hidden;
  overflow-x: auto;

  .header {
    min-width: 640px;
    font-weight: bold;
    font-size: 0.9rem;
    padding: 10px;
    background-color: rgba(170, 162, 162, 0.436);

    div {
      text-align: center;
    }
  }

  .row {
    border: 1px solid rgba(170, 162, 162, 0.436);
    border-top: 0px;

    div {
      display: flex;
      justify-content: center;
    }
  }

  .inputFields {
    width: 15ch;
  }
}

.addLabelBtn {
  background-color: #c71e21;
  color: #ffffff;
  width: fit-content;
  height: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.labelRowCss {
  position: relative;
  min-width: 640px;

  .labelRowCssDeleteBtn {
    border: none;
    color: red;
    padding: 0;
    align-items: center;
    display: inline-flex;
    border: 1px solid red;
    background: none;
    border-radius: 50%;
    padding: 5px 4px;
  }
}
.admin-stock-modal {
  width: 41% !important;
}
@media screen and (max-width: 1440px) {
  .admin-stock-modal {
    width: 53% !important;
  }
}
@media screen and (max-width: 1280px) {
  .admin-stock-modal {
    width: 59% !important;
  }
}
@media screen and (max-width: 1024px) {
  .admin-stock-modal {
    width: 74% !important;
  }
}
@media screen and (max-width: 768px) {
  .admin-stock-modal {
    width: 89% !important;
  }
}
.add-address-modal {
  z-index: 999;
}
.fileUploadDesign {
  cursor: pointer;
  div {
    .text {
      color: #c71e21;
      border: none;
      padding: 0;
    }
    .icon {
      color: #c71e21;
      margin-right: 8px;
    }
  }
}

.labelRowCssDeleteBtn {
  border: none;
  color: red;
  padding: 0;
  align-items: center;
  display: inline-flex;
  border: 1px solid red;
  background: none;
  border-radius: 50%;
  padding: 5px 4px;
}
