@import "../../../../shared/style/Variables.scss";

.productCardMain {
  width: fit-content;
  margin-bottom: 20px;
  border: 1px solid #efefef;
  border-radius: 30px;
  opacity: 1;
  padding: 10px;
  .offer-line {
    position: absolute;
    right: 14px;
    top: 0;
    border-radius: inherit;
    background-color: $primary-color;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    .product-detail-offer-text {
      padding: 10px 15px;
      color: $white;
      margin: 0;
    }
  }
}

.product-image {
  width: 100%;

  img {
    border-radius: 21px;
    width: 380px;
    object-fit: cover;
  }
}

.product-details {
  .title {
    padding: 15px 0 20px;
    font-size: 24px;
    margin: 0;
  }

  .price {
    font-size: 24px !important;
    color: $primary-color;
    font-weight: 500;
    margin-bottom: 15px;
    span {
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .inventory-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      p:nth-child(1) {
        font-size: 16px;
        color: #383838;
        margin-bottom: 2px;
      }

      p:nth-child(2) {
        font-size: 18px;
      }
    }
  }

  .AddToCartBtnContainer {
    // width: 90%;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    // width: 370px;
    .wishlistBtn {
      height: 67px;
      width: 67px;
      border: 1px solid #034ea2 !important;
      border-radius: 11px;
      color: #034ea2;
    }
  }
}

@media screen and (max-width: 1440px) {
  .product-image {
    img {
      width: 290px !important;
    }
  }

  // .product-details {
  //   .AddToCartBtnContainer {
  //     width: 290px !important;
  //   }
  // }
}

@media screen and (max-width: 1024px) {
  .product-image {
    img {
      width: 280px !important;
    }
  }

  // .product-details {
  //   .AddToCartBtnContainer {
  //     width: 280px !important;
  //   }
  // }
}

@media screen and (max-width: 768px) {
  .product-image {
    img {
      width: 330px !important;
    }
  }

  .product-details {
    .title {
      font-size: 18px;
    }

    .price {
      font-size: 22px !important;
    }

    .inventory-info {
      div {
        p:nth-child(1) {
          font-size: 14px;
          color: #383838;
          margin-bottom: 2px;
        }

        p:nth-child(2) {
          font-size: 14px;
        }
      }
    }

    .AddToCartBtnContainer {
      .wishlistBtn {
        height: 55px;
        width: 55px;
        border: 1px solid #034ea2 !important;
        border-radius: 11px;
        color: #034ea2;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-image {
    img {
      width: 290px !important;
    }
  }

  .similar-products-content {
    justify-content: center;
  }

  .product-details {
    .price {
      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
}

.theme-p {
  color: $primary-color !important;
  font-weight: 500;
  font-size: 17px;
}

@media screen and (max-width: 480px) {
  .theme-p {
    font-size: 14px;
  }

  .productCardMain {
    .offer-line {
      right: 5px;
      .product-detail-offer-text {
        padding: 4px 10px;
        font-size: 12px !important;
      }
    }
  }
}
