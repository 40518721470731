@import "../../../../../shared/style/Variables.scss";

p {
  line-height: 1.6rem;
}

.contentPadding {
  padding: 0px 5rem;
}

.products-bg-color,
.product-main-content-layout {
  background-color: $white;
}

.ant-breadcrumb-link,
.products-breadcrumb-active {
  font-size: 16px;
}

.products-breadcrumb-active {
  color: $primary-color;
  font-weight: 600;
}

.product-main-content-layout {
  padding: 40px 0 !important;
}

.product-details-left {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  .selectedImage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border-radius: 21px;
      width: 100%;
      height: 400px;
      object-fit: contain;
    }
  }

  .allImages {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      border-radius: 8px;
      height: 100px;
      width: 100px;
    }
  }
}

.products-details-right {
  &__top {
    font-weight: 600;
    line-height: 1.6rem;

    &__title {
      font-size: 28px;
      margin-bottom: 22px;
      font-weight: normal;
      width: 64%;
      line-height: 2rem;
    }

    &__dprice {
      font-size: 30px;
      font-weight: 700;

      span {
        margin-left: 15px;
        font-weight: 500 !important;
      }

      &__mprice {
        font-size: 18px;
        color: #a2a2a2;
      }

      &__save {
        font-size: 18px;
        color: $primary-color;
      }
    }

    position: relative;

    &__favourite {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .icon {
        color: $primary-color;
      }
    }
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;

    .icon {
      color: $primary-color;
      max-width: 30px;
      max-height: 25px;
    }
  }
}
.notify-btn {
  padding: 0.5rem;
  height: fit-content;
  border-radius: 6px;
  border-color: #034ea2;
}

.descrpTitle {
  font-size: 18px;
}

.descrp {
  font-size: 16px;
  color: #5d5d5d;
}

.productDetailsRightBottom {
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: center;

  p {
    margin: 0;
    font-size: 16px;
    color: #a7a7a7;
    font-weight: 500;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .addToCartBtn:hover,
  .addToCartBtn:focus {
    color: $white;
  }

  .quantityBtn {
    height: 67px;
    width: 67px;
    text-align: center;
    font-size: 22px;
    border-radius: 8px;
  }
}

.description-row {
  padding: 40px 0;
}

.main-description {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  color: $primary-color;
}

.main-description-details {
  padding: 20px 0 0;
  font-size: 16px;
  color: $black;
  line-height: 2rem;
  h1 {
    font-size: 22px;
    font-weight: bold;
  }
  h2 {
    font-size: 22px;
    font-weight: bold;
  }
  .ul {
    font-size: 16px;

    li {
      line-height: 2rem;
    }
  }
}

.features-title {
  font-size: 22px;
  font-weight: bold;
}

.features-list {
  font-size: 16px;

  li {
    line-height: 2rem;
  }
}

.minus {
  background-color: #d0d0d2;
  border-radius: 7px;
}

.plus {
  background-color: $primary-color;
  color: $white;
  border-radius: 7px;
}

.similar-products {
  font-size: 39px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;

  span {
    position: absolute;
    right: 0;
    font-size: 20px;
    color: $primary-color;
    cursor: pointer;
  }
}

.product-detail-offer-text {
  font-weight: bold;
  font-size: 1rem;
  font-family: Roboto;
  color: #c71e21;
}
// .similar-products-content {
//   position: relative;
//   display: flex;
//   // justify-content: space-between;
//   gap: 10px;
//   flex-wrap: wrap;
//   padding: 25px 0;
// }
@media screen and (max-width: 1280px) {
  .minus,
  .plus,
  .value {
    height: 57px !important;
    width: 57px !important;
  }
}

@media screen and (max-width: 1024px) {
  .contentPadding {
    padding: 0px 1.4rem;
  }

  .ant-breadcrumb-link,
  .products-breadcrumb-active {
    font-size: 14px;
  }

  .product-main-content-layout {
    padding: 20px 0 !important;
  }

  .product-details-left {
    justify-content: start;
    // align-items: flex-start;

    .selectedImage {
      img {
        width: 100%;
        height: 200px;
      }
    }

    .allImages {
      img {
        height: 50px;
        width: 50px;
      }
    }
  }

  .products-details-right {
    &__top {
      &__title {
        font-size: 22px;
        margin-bottom: 15px;
        line-height: 1.6rem;
        width: 70%;
      }

      &__dprice {
        font-size: 22px;

        &__mprice {
          font-size: 14px;
        }

        &__save {
          font-size: 14px;
        }
      }

      &__favourite {
        font-size: 12px;

        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    &__middle {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .productDetailsRightBottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    text-align: center;

    .quantityBtn {
      height: 60px;
      width: 60px;
      font-size: 18px;
    }
  }

  .features-title {
    font-size: 18px;
  }

  .main-description-details,
  .descrp,
  .features-list {
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .main-description {
    text-align: left;
  }

  .similar-products {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .similar-products-content {
    gap: 20px;
  }
}

@media screen and (max-width: 480px) {
  .products-details-right__top__title {
    width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 15px;
  }
  .products-details-right__middle {
    font-size: 16px;
  }
  .products-details-right__top__dprice {
    width: 100%;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .products-details-right__top__favourite {
    position: relative;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .products-details-right__top__favourite .icon {
    width: 24px !important;
    height: 24px !important;
  }
}

.horizontal-scroller {
  min-height: 630px;
}
.detail-outer-table {
  display: flex;
  justify-content: center;
}
.detail-custom-table {
  border: 1px solid black;
  th,
  td {
    width: 400px;
    border: 1px solid black;
    padding: 0.5rem;
    text-align: left;
    font-size: 1rem;
  }
  th {
    background-color: #f0f2f2;
  }
}
.text-left {
  text-align: left;
}
@media screen and (max-width: 1280px) {
  .horizontal-scroller {
    min-height: 550px;
  }
}
@media screen and (max-width: 1024px) {
  .horizontal-scroller {
    min-height: 590px;
  }
}

@media screen and (max-width: 480px) {
  .horizontal-scroller {
    min-height: 540px;
  }
  .detail-custom-table {
    th,
    td {
      min-width: 150px;
    }
  }
}
.arrow-col {
  display: flex;
  align-items: center;
}
.arrow-icon {
  svg {
    height: 2rem !important;
    width: 2rem !important;
  }

  color: #c71e21;
  font-size: 2rem !important;
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-btn {
  border: none;
  margin: auto;
}
.scroll-btn:disabled {
  border: none !important;
  background: none !important;
}

.download-tech-btn {
  font-size: 16px;
}
