@import "../../../../../shared/style/Variables.scss";

p {
  line-height: 1.6rem;
}

.contentPadding {
  padding: 0px 5rem;
}

.cart-bg-color,
.product-main-content-layout {
  background-color: $white;
}

.ant-breadcrumb-link,
.products-breadcrumb-active {
  font-size: 16px;
}

.products-breadcrumb-active {
  color: $primary-color;
  font-weight: 600;
}

.cart-main-content-layout {
  padding: 0;
  background: $white;
}

.theme-btn {
  background-color: $primary-color;
  color: $white;
  width: 100%;
  padding: 18px !important;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.theme-btn1 {
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: $font-color;
  width: 100%;
  padding: 18px !important;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
}

.page-title {
  font-size: 32px;
  font-weight: bold;
}

.steps {
  padding: 40px 0;
  display: flex;
  justify-content: center;

  .ant-steps {
    width: 40%;
  }
}

.ant-steps-item-title {
  font-size: 22px;
}

.cart-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 0px;
  margin-bottom: 70px;

  .search-container {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }

    input {
      padding: 15px;
      background-color: #f4f4f4;
      padding-left: 50px;
      border: 1px solid #d3d3d3;
      border-radius: 17px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.pagination {
  padding: 30px 0;

  .ant-pagination-item-active {
    border-radius: 5px;
  }

  li {
    font-size: 1rem;

    border: none;
  }
}

.cart-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-left: 1px solid $primary-color;
  padding-left: 50px;
  margin-bottom: 70px;

  .coupon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    // cursor: pointer;
    padding: 24px;
    background: $bg-color;
    border: 1px solid $border-color;
    border-radius: 16px;

    .icon1 {
      padding: 13px;
      background-color: $primary-color;
      color: $white;
      border-radius: 16px;

      svg {
        width: 30px;
      }
    }

    span {
      display: inline-flex;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .summary-container {
    padding: 33px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-color: $primary-color;
    cursor: auto;

    p {
      font-size: 21px;
      line-height: 1.6rem;
      margin-bottom: 10px;
    }

    .ant-divider-horizontal {
      margin: 15px 0 !important;
    }

    .title {
      padding: 0;
      font-size: 22px;
      font-weight: medium;
    }

    .details {
      width: 100%;
      display: flex;
      justify-content: space-between;

      p {
        color: #5d5d5d;
      }
    }

    .details1 {
      p {
        font-weight: normal;
      }
    }

    .details2 {
      p {
        font-weight: medium;
      }

      .totalPrice {
        color: $primary-color;
        font-weight: 500 !important;
      }
    }
  }
}

//Modal
.ant-modal {
  padding-bottom: 0;
  border-radius: 39px !important;
  border: 1px solid $primary-color !important;
  overflow: hidden;
  background-color: $white;
  box-shadow: none;
  top: 50px !important;
}

.ant-modal-content {
  padding: 30px;
}

.ant-modal-header {
  padding-bottom: 0;
  border: none;

  .ant-modal-title {
    font-size: 24px !important;
  }
}

.ant-input-prefix {
  margin-right: 8px !important;
}

.add-address-modal {
  width: 50% !important;
}

.modalLabels {
  div {
    div {
      label {
        font-size: 16px !important;
        color: #383838 !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
      }
    }
  }
}

.modalInputs {
  border: 1px solid #d0d0d2;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

.modalInputsPrefixSelector {
  // border: 1px solid #d0d0d2;
  // padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  span {
    input {
      border-radius: 8px;
      padding: 10px;
      min-height: 48px;
    }
  }
}

.addAddressBtn {
  width: 55%;
}

.shipping-container,
.billing-container {
  position: relative;
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2em;
    .icon {
      min-height: 40px;
      max-height: 40px;
    }
    .closeIcon {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: red;
      color: white;
      border-radius: 100vw;
      padding: 5px;
      font-size: 1.4em;
      cursor: pointer;
    }

    .title {
      margin: 0;
    }

    .theme-btn1 {
      width: 30%;
      padding: 10px !important;
      border: 1px solid #707070;
    }
  }
}

.address-row {
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: start;

  input[type="radio"] {
    margin-top: 12px;
    width: 1.3em;
    height: 1.3em;
  }

  label {
    margin: 0;
    height: fit-content !important;

    p {
      margin-bottom: 0px;
    }

    .name {
      font-weight: 500;
      font-size: 21px;
      min-height: 40px;
      line-height: 1.7em;

      .theme-btn1 {
        width: 100%;
        margin-left: 15px;
        font-weight: 400 !important;
        border: 1px solid #707070;
        padding: 5px 10px !important;

        span {
          font-size: 16px !important;
        }
      }
    }

    .address,
    .phone {
      color: #5d5d5d;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .cart-left {
    padding-right: 0px;
  }

  .cart-right {
    padding-left: 20px;

    .coupon-container {
      span {
        font-size: 18px;
      }
      .icon1 {
        padding: 10px;
      }
    }
  }
  .add-address-modal {
    width: 80% !important;
  }
}

@media screen and (max-width: 1024px) {
  .cart-left,
  .cart-right {
    padding: 0;
  }

  .contentPadding {
    padding: 0px 2rem !important;
  }
}

@media screen and (max-width: 768px) {
  .contentPadding {
    padding: 0px 1rem !important;
  }

  .ant-breadcrumb-link,
  .products-breadcrumb-active {
    font-size: 14px;
  }

  .page-title {
    font-size: 26px;
    font-weight: bold;
  }

  .cart-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
    padding: 0;

    .search-container {
      position: relative;

      .icon {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
      }

      input {
        padding: 10px;
        background-color: #f4f4f4;
        padding-left: 50px;
        font-size: 16px;
      }
    }
  }

  .cart-right {
    margin-bottom: 30px;
    padding: 0;
    border: none;

    .coupon-container {
      padding: 20px;

      span {
        font-size: 18px;
      }

      .icon1 {
        padding: 12px;
      }
    }

    .summary-container {
      p {
        font-size: 16px;
      }

      .title {
        font-size: 20px;
      }
    }
  }

  .shipping-container .top .theme-btn1,
  .billing-container .top .theme-btn1 {
    width: fit-content;
    padding: 10px !important;
    height: 100%;

    span {
      font-size: 16px;
    }
  }

  .ant-modal-header,
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-modal-content {
    padding: 20px;
  }

  .add-address-modal {
    width: 90% !important;
  }

  .steps .ant-steps {
    width: 80%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .address-row {
    label {
      .name {
        .theme-btn1 {
          padding: 0px 10px !important;
        }
      }
    }

    gap: 10px;
    input[type="radio"] {
      margin-top: 8px;
      width: 1em !important;
      height: 1em !important;
    }
  }
}

.no-prod {
  padding: 20px;
  font-size: 24px;
  text-align: center;
}

.couponInput {
  height: 66px;
}

.couponBtn {
  width: 100%;
}

.ant-modal-close {
  top: 10px;
  right: 10px;
}

.modalLabelsSelect {
  .ant-select-arrow {
    position: absolute !important;
    left: 4% !important;
  }

  .ant-select-selector {
    .ant-select-selection-search {
      display: flex;
      input {
        height: 100% !important;
        margin-left: 14% !important;
        text-indent: 10px !important;
      }
    }
    .ant-select-selection-placeholder {
      padding-left: 10% !important;
    }
  }
}

.couponList {
  max-height: 270px;
  overflow-y: auto;
  width: 100%;
  .couponRow {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    .couponRowLeft {
      display: flex;
      flex: 0.85;
      flex-direction: column;
      .name {
        font-weight: 600;
        margin-bottom: 5px;
      }
      .desc {
        color: #5d5d5d;
        font-size: 16px;
        line-height: 1.2em;
      }
    }
    .couponBtn {
      flex: 0.15;
      background-color: transparent;
      border: none;
      outline: none;
      color: rgb(89, 89, 229);
      span {
        font-size: 20px;
      }
    }
  }
}
