@import "./Mixins.scss";
//@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Colors

$primary-color: #c71e21;
$secondary-color: #707070;
$link-color: #0000ff;
$light-secondary-color: #6dade6;
$danger-color: #f04134;
$success-color: #7bc142;
$info-color: #a4d9d3;

$bg-color: #e4e0da40;
$border-color: #cecece;

$black: #0c0b0f;
$white: #ffffff;
$gray: #5f5f5f;
$dark-gray: #787878;
$medium-gray: #a5a5a5;
$light-gray: #f8f8f8;
$blue: #202c55;

$title-font: "Inter", sans-serif;

$font-family: "Inter", sans-serif;
$font-color: #222222;

$page-background: #f3f4f5;

$sidebar-text-color: $white;

$header-color: $black;

$success-light-bg-color: #F1FFEA;
$success-light-border-color: #22A203;
$danger-light-bg-color: #FFE3DE;
$danger-light-border-color: #E81C01;
$secondary-light-bg-color: #F0F0F0;
$secondary-light-border-color: #696969;

// We have multiple shadow levels to represent perspective
$shadow-0: 0 2px 2px rgba(0, 0, 0, 0.1);
$shadow-1: 2px 4px 6px rgba(0, 0, 0, 0.1);
* {
  transition: box-shadow 0.1s ease-in;
}

$header-height: 77px;
@import "./Markdown.scss";

//fonts
// $font-primary: 'Inter', sans-serif;
$font-primary: "Roboto", sans-serif;
