@import "../../../../../shared/style/Variables.scss";

.sales-page-title {
  margin: 15px 0 30px;
}

.add-discount-container {
  position: relative;
  justify-content: start !important;
}
.sales-checkout-details {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  gap: 10px !important;
  .quantity {
    flex: initial !important;
  }
}
.sales-checkout-pagination {
  display: flex;
  justify-content: end;
}

.add-discount-percent {
  display: flex !important;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  .ant-form-item {
    margin: 0 !important;
  }
  justify-content: space-evenly !important;
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    .ant-radio {
      top: 0 !important;
    }
  }
}
.add-discount-input {
  .ant-form-item {
    margin: 0 !important;
  }
  input {
    background-color: white;
    padding: 15px 20px;
    border: 1px solid #707070;
    border-radius: 8px;
  }
}

.add-discount-button {
  padding: 10px 20px;
  background-color: #989898;
  color: white;
  border-radius: 8px;
  height: 100%;
  flex-grow: 2;
}

.add-discount-close {
  position: absolute;
  top: -2%;
  right: -2%;
  background-color: red;
  color: white;
  padding: 5px;

  border-radius: 100vw;
  cursor: pointer;
  svg {
    height: 15px;
  }
}
@media screen and (max-width: 768px) {
  .sales-page-title {
    margin: 15px 0 15px;
  }
}

@media screen and (max-width: 480px) {
  .add-discount-container {
    flex-direction: column !important;
  }
}
