.privacy-policy-container {
  p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
  }
  h2 {
    font-size: 20px;
    margin-top: 12px;
    margin-bottom: 10px;
  }
  .profile-sub-heading {
    font-size: 16px;
    font-weight: 500;
    color: #0c0b0f;
  }
  ul {
    li {
      font-size: 14px;
      line-height: 1.8em;
    }
  }
}

@media screen and (max-width: 480px) {
  .privacy-policy-container {
    ul {
      padding-left: 30px;
      li {
        font-size: 14px;
        line-height: 1.8em;
      }
    }
  }
}
