@import "../../../../../shared/style/Variables.scss";

.bg-white {
  background-color: #fff !important;
  padding: 0;
}

.sales-order-details-badge {
  display: flex;
  .order-status {
    flex: 0;

    background-color: #efffea;
    padding: 5px 20px;
    width: fit-content;
    margin-left: 8px;
    border-radius: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sales-table-button-container {
  gap: 10px;
}
.minus,
.plus,
.value {
  height: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 7px;
  font-size: 1.4rem;
}
.quantity {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  width: 7rem;
  // margin: 0 auto;
  .quantity-value {
    text-align: left;
    font: normal normal 18px/22px Roboto;
    letter-spacing: 0px;

    opacity: 1;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.sales-dealer-list-table .ant-table-column-title {
  text-align: center;
  font: normal normal 14px/17px Roboto;
  letter-spacing: 1.02px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
.sales-dealer-list-table {
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 10px !important;
    text-align: center;
    align-items: center;
    font: normal normal 14px/17px Roboto;
    letter-spacing: 0.1px;
    color: #131523;
    opacity: 1;
  }
  .anticon svg {
    height: 0.8rem !important;
    width: 0.8rem !important;
  }
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    /* padding: 15px 20px; */
    text-align: center;
  }
}

.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td {
  text-align: center;
}

.floatingBtn {
  position: fixed;
  z-index: 9999;
  height: fit-content;
  width: fit-content;
  right: 5%;
  bottom: 5%;
  box-shadow: 0px 9px 14px #00000058;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.orderDetailsHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .sales-order-details-badge,
  .sales-table-button-container {
    gap: 10px;
    flex-direction: column;
  }
}
@media screen and (max-width: 480px) {
  .floatingBtn {
    padding: 15px 20px !important;
    gap: 12px;
    font-size: 15px;
  }
}
