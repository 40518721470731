@import "../../../../../shared/style/Variables.scss";

.content {
  background-color: white;
}

.header-logo-wrapper {
  width: 270px;

  .dash-btn {
    border-radius: 5px;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .header-logo-wrapper {
    width: 168px;
  }
}

.search {
  padding: 20px 50px;
}

.search-input {
  border-radius: 5px;
  align-items: center;
}

.header-search {
  span {
    input {
      padding: 18px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background-color: #d0d0d240;
      border: none;
      outline: none;
    }
    span {
      button {
        padding: 16px !important;
        height: 100% !important;
        font-size: 16px !important;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }
  }
}

.category-page-title {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color: $primary-color;
}

.categories-container {
  justify-content: start;
  align-items: start;
  column-gap: 45px;
  row-gap: 30px;

  .category-card {
    .ant-card-body {
      text-align: center;
      padding: 10px 10px 25px 10px;
    }
  }
  .card-box {
    border-radius: 26px;
    border: 1px solid #ebebeb;
    background-color: #f8f8f8;

    .photo {
      border-radius: 26px;
      padding: 10px;
      height: 284px;
      width: 286px;
      object-fit: cover;
    }

    .img-text {
      font-size: 22px;
      margin: 0;
      width: 266px;
      font-weight: 500;
    }
  }
}

.card-box {
  cursor: pointer;
  background-color: light-gray;
  border-radius: 10px;
}

@media screen and (max-width: 1280px) {
  .categories-container {
    column-gap: 20px;
    row-gap: 20px;

    .card-box {
      .photo {
        height: 270px;
        width: 265px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .category-page-title {
    font-size: 32px;
    text-align: center;
  }

  .categories-container {
    justify-content: center;

    .card-box {
      .photo {
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .category-page-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .categories-container {
    gap: 20px;

    .category-card {
      .ant-card-body {
        padding: 5px 10px 15px 10px;
      }
    }

    .card-box {
      .photo {
        width: 180px;
        height: 180px;
      }

      .img-text {
        font-size: 14px;
        width: 100px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .categories-container {
    .card-box {
      .photo {
        width: 140px;
        height: 140px;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .categories-container {
    .card-box {
      .photo {
        width: 130px;
        height: 130px;
      }
    }
  }
}
