.salesDashboard {
  padding-top: 20px;
  padding-bottom: 20px;
}
.salesDashboardDropdownContainer {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  // gap: 20px;
  .salesDashboardDropdown {
    .ant-select-selector {
      padding: 10px 15px;
      min-width: 310px !important;
      border: 1px solid #034ea2 !important;
      border-radius: 11px !important;
      .ant-select-selection-placeholder {
        color: black !important;
        font-size: 16px;
      }
    }
    .ant-select-arrow {
      .anticon {
        svg {
          width: 1rem !important;
          height: 1rem !important;
          color: #034ea2;
        }
      }
    }
  }
}
.salesInsightBoxContainer {
  min-height: 98% !important;
}
.salesInsightBox {
  min-height: 429px !important;
}
.salesDashboardBtns {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  button {
    border: 1px solid;
    font-size: 18px;
    width: 90%;
    padding: 30px 25px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: 13px;
    position: relative;
    .icon {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  button:nth-child(1) {
    color: #51459e;
    background-color: #f2e9ff;
    border-color: #51459e60;
  }
  button:last-child {
    color: #ac6755;
    background-color: #fef3f0;
    border-color: #ac675586;
  }
}
.salesDashboardDropdownForm {
  margin-left: 15px;
}

@media screen and (max-width: 500px) {
  .insight-box {
    min-height: fit-content !important;
  }
  .insight-box .insight-content-box {
    min-height: fit-content !important;
  }
  .salesDashboardDropdownForm {
    margin: 0;
  }
  .salesDashboardDropdownContainer {
    justify-content: center;
    .salesDashboardDropdown {
      width: 100% !important;
    }
  }

  .salesDashboardBtns {
    button {
      width: 100%;
    }
  }
}

.dashboard-order-chart {
  min-width: 260px !important;
}
