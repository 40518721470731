.admin-banner-form {
  min-height: 100%;
}

.banner-form-input {
  background-color: #fff !important;
  height: 45px;
  border-radius: 8px !important;
}

.banner-form-select {
  .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    height: 45px !important;
    border-radius: 8px !important;
  }
}

.banner-form-select-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  object-fit: cover;
  border-radius: 8px;
}

.bannerActionButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.bannerTableImg {
  width: 100px;
  height: 70px;
  border-radius: 8px;
  object-fit: contain;
}

.ant-table.ant-table-middle .ant-table-filter-trigger {
  color: white;
  background-color: rgba(255, 255, 255, 0.27);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  overflow-y: auto;
  padding: 15px;
  min-width: 204px;
  min-height: 214px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
