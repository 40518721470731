@import "../../../../shared/style/Variables.scss";

.productSimilarCard {
  // width: fit-content;
  height: fit-content;
  width: 380px;
  margin: 20px;
  margin-bottom: 20px;
  border: 1px solid #efefef;
  border-radius: 30px;
  opacity: 1;
  padding: 10px;

  .product-image {
    width: 100%;

    img {
      border-radius: 21px;
      // width: 380px;
      width: 100%;
      height: 320px;
      object-fit: contain;
    }
  }

  .product-details {
    .title {
      padding: 15px 0 20px;
      font-size: 20px;
      margin: 0;
    }

    .price {
      font-size: 22px !important;
      color: $primary-color;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .inventory-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      div {
        p:nth-child(1) {
          font-size: 16px;
          color: #383838;
          margin-bottom: 2px;
        }

        p:nth-child(2) {
          font-size: 16px;
        }
      }
    }
  }

  .AddToCartBtnContainer {
    // width: 90%;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    .addToCartBtn {
      width: 100% !important;
    }

    // width: 370px;
    .wishlistBtn {
      height: 67px;
      width: 67px;
      border: 1px solid #034ea2 !important;
      border-radius: 11px;
      color: #034ea2;
    }
  }
}

@media screen and (max-width: 1440px) {
  .productSimilarCard {
    .product-image {
      img {
        width: 100% !important;
        height: 290px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .productSimilarCard .product-image img {
    height: 240px;
  }
}

@media screen and (max-width: 1024px) {
  .productSimilarCard {
    // width: 32%;

    .product-image {
      img {
        width: 100% !important;
        height: 280px;
      }
    }

    .AddToCartBtnContainer {
      // width: 90%;
      display: flex;
      justify-content: space-between;
      gap: 15px;

      // width: 370px;
      .wishlistBtn {
        height: 57px;
        width: 57px;
        border: 1px solid #034ea2 !important;
        border-radius: 11px;
        color: #034ea2;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .productSimilarCard {
    // width: 48%;

    .product-image {
      img {
        width: 100% !important;
        height: 280px;
      }
    }

    .AddToCartBtnContainer {
      .wishlistBtn {
        height: 57px;
        width: 57px;
        border: 1px solid #034ea2 !important;
        border-radius: 11px;
        color: #034ea2;
      }
    }
  }

  .product-details {
    .title {
      font-size: 18px;
    }

    .price {
      font-size: 22px !important;
    }

    .inventory-info {
      div {
        p:nth-child(1) {
          font-size: 14px;
          color: #383838;
          margin-bottom: 2px;
        }

        p:nth-child(2) {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .productSimilarCard {
    width: 290px;
    margin: 0px;
    margin-right: 10px;
    .product-image {
      width: 100% !important;
      img {
        width: 100% !important;
        // height: 280px;
      }
    }
  }

  .similar-products-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
  }
}
