@import "../../../../../shared/style/Variables.scss";

.sales-profile-row {
  padding: 3rem !important;
  .btn-col {
    display: flex;
    justify-content: flex-end;
  }
  .sales-profile-btn {
    height: 4rem;
    width: 13.5rem;
    border-radius: 12px;
    margin: 0 1rem;
    border-color: #034ea2 !important ;
    background-color: white !important;
    font-weight: 500;
    font-size: 18px;
    font-family: Roboto;
    letter-spacing: 0.13px;
    color: #000000;
    opacity: 1;
    img {
      margin: 0 0.5rem;
    }
    // color: $primary-color !important  ;
  }
}
.sales-profile-main-title-row-2 {
  padding: 0rem 3rem !important;
  margin-bottom: 2rem !important;
}

.sales-profile-main-title {
  text-align: center;
  font: normal normal bold 48px/58px Roboto;
  letter-spacing: 0px;
  color: #383838;
  opacity: 1;
}

.sales-profile-container {
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    justify-content: center;
  }
}
.sales-profile-form {
  width: 60%;
  .ant-input {
    background-color: white !important;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: #f0f0f5 !important;
    .ant-input {
      background-color: #f0f0f5 !important;
    }
  }
  .modalInputs {
    height: 4rem;
  }
  .btn-center {
    display: flex;
    justify-content: center;
  }
  .resetPasswordLink {
    color: $primary-color;
    display: flex;
    justify-content: center;
    font-size: 1rem;
  }
}

.sales-reset-form {
  width: 30%;
  .ant-input {
    background-color: white !important;
  }
  .modalInputs {
    height: 3rem;
  }
  .btn-center {
    display: flex;
    justify-content: center;
  }

  .save-btn {
    height: 50% !important;
  }
  .resetBtn {
    width: 100%;
  }
}

.sales-pic {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  object-fit: cover;
}
.sales-pic-text {
  font-weight: bold;
}
.fileUploadDesign {
  display: flex;
  justify-content: space-between;
  color: #a7a7a7;
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  div {
    padding: 9px 15px;
    img {
      margin-right: 8px;
    }
  }

  .text {
    margin: 0;
    padding: 9px 15px;
    height: 100%;
    border: none;
    color: #a7a7a7;
    border-left: 1px solid $border-color;
  }
}
.sales-radio-button {
  padding: 10px 20px;
  border: 1px solid #d0d0d2;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  label {
    margin-top: 10px !important;
  }
}

.handleUploadCss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #e4e4ed;
  padding: 5px 15px;
  span {
    display: inline-flex;
    color: $primary-color;
    cursor: pointer;
  }
}
@media screen and (max-width: 1024px) {
  .sales-profile-form {
    width: 100%;
  }
  .sales-radio-button {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 480px) {
  .sales-profile-form {
    width: 100%;
  }
  .sales-radio-button {
    padding: 15px;
    flex-direction: column;
  }

  .sales-reset-form {
    width: 100%;
  }
  .sales-profile-main-title {
    font-size: 22px;
  }
  .sales-profile-row {
    padding: 1rem !important
    ;
  }
  .btn-col {
    justify-content: center !important;
    gap: 10px;
    flex-wrap: wrap;
  }
}
.ant-upload.ant-upload-select {
  display: block;
}
.LastUploadedFileCss {
  display: flex;
  justify-content: space-between;
  a {
    display: flex;
    gap: 5px;
    color: $primary-color;
  }
}
