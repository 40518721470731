@import "../../../../shared/style/Variables.scss";

.modal-product-card {
  width: 100% !important;
  border: 1px solid !important;
  padding: 20px !important;
  border-radius: 8px;
  margin-bottom: 15px;
  border-color: $border-color !important;
}
.modal-cart-image {
  img {
    width: 100px !important;
    height: 100px !important;
  }
}
.modal-cart-quantity {
  justify-content: flex-start !important;
}

@media screen and (max-width: 500px) {
  .modal-product-card {
    width: 100% !important;
    padding: 15px !important;
    position: relative;
    flex-direction: row !important;
    gap: 10px !important;
  }
  .modal-cart-image {
    flex: 0.25 !important;
    img {
      width: 60px !important;
      height: 60px !important;
    }
  }
  .modal-cart-details {
    padding: 0 !important;
  }
  .modal-cart-quantity {
    justify-content: flex-start !important;
  }
  .modal-cart-cancel {
    position: absolute !important;
    right: 4%;
    top: 4%;
  }
}
