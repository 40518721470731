@import "../../../../shared/style/Variables.scss";

.checkout-card-image {
  img {
    height: 151px;
    width: 256px;
  }
}
.checkout-product-card {
  width: 100% !important;
}
.checkout-card-quantity {
  justify-content: start !important;
}
.checkout-card-cancel {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between !important;
  .price {
    font-size: 24px;
    font-weight: bold;
    color: $primary-color;
  }
  button {
    border: 1px solid #d0d0d2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 8px !important;
    color: $primary-color;
  }
}

@media screen and (max-width: 1280px) {
  .checkout-card-image {
    img {
      height: 110px !important;
      width: 140px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .cart-product-card {
    padding-bottom: 15px;
    padding-top: 15px;
    flex-direction: column;
    gap: 5px;
    // .checkout-card-image {
    //   // order: 2 !important;
    // }
    .checkout-card-cancel {
      // order: 4 !important;
      flex-direction: row;
      .price {
        font-size: 28px !important;
      }
    }
    .checkout-card-details {
      .sku {
        justify-content: space-between !important;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }

    .cancel {
      .price {
        font-size: 18px;
        margin: 0;
      }
    }
  }
}
