@import "../../../../../shared/style/Variables.scss";

.order-details-topbar {
  background-color: $bg-color;
  padding: 20px 15px;
}
.order-details-title {
  font-size: 23px;
  font-weight: 500;
  margin: 0;
  padding-left: 20px;
}
.order-details-top {
  display: flex;
  justify-content: center !important;
  align-items: center;
  span {
    display: flex;
    margin-left: 20px;
    color: #c71e21;
    align-items: center;
    .ant-badge-status-dot {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }
    .ant-badge-status-text {
      display: none;
    }
  }
}
.orderSingleProd {
  margin: 20px 0;
}
.orderCardContainer {
  display: flex;
  gap: 20px;
  justify-content: start;
  margin-bottom: 20px;

  .imgContainer {
    height: 100%;
    img {
      width: 214px;
      height: 150px;
      object-fit: fill;
      border-radius: 8px;
    }
  }
  .infoContainer {
    .title {
      width: 100%;
      font-size: 22px;
      color: $black;
      font-weight: normal !important;
      margin: 0;
      line-height: 1.8em;
      height: fit-content;
    }
    .sku {
      width: 100%;
      color: #808080;
      font-size: 20px;
    }
  }
}
.orderPrice {
  font-size: 24px;
  color: $primary-color;
  font-weight: bold;
}

.order-addres-bar {
  display: flex;
  padding: 0 40px;
  .left {
    width: 30%;
    font-size: 22px;
    display: flex;
    gap: 10px;
    svg {
    }
  }
  .right {
    width: 70%;
    font-size: 22px;
    .name {
      font-weight: bold;
    }
    .phone-number,
    .address {
      font-size: 20px;
      color: #959595;
    }
  }
}

.backBtnOrder {
  display: flex;
  align-items: center;
  font-size: 17px;
  gap: 18px;
  cursor: pointer;
  width: fit-content;
}
.order-details-page-title {
  font-size: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 15px;
  .orderTop {
    font-size: 12px;
    background-color: #d5dcdf;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 50vw;
    .orderBadge {
      .ant-badge-status-dot {
        height: 15px;
        width: 15px;
        padding: 0;
        margin-right: 10px;
      }
      .ant-badge-status-text {
        display: none;
      }
    }
  }
}

.order-details-buttons {
  display: flex;
  justify-content: end;
  .theme-btn {
    width: fit-content;
    &:nth-child(1) {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .order-details-title {
    font-size: 18px;
  }
  .order-addres-bar {
    padding: 0 20px;
    .left {
      width: 45%;
      p {
        margin-bottom: 10px;
      }
    }
    .right {
      width: 65%;
    }
  }
}
@media screen and (max-width: 1024px) {
  .order-details-buttons {
    justify-content: start;
    margin: 15px 0;
  }
  .order-details-title {
    font-size: 18px;
  }
  .order-addres-bar {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    .left {
      width: 100%;
      font-size: 18px;
      p {
        margin-bottom: 10px;
      }
    }
    .right {
      width: 100%;
      font-size: 18px;
      padding-left: 30px;
      .name {
        margin-bottom: 10px;
      }
      .phone-number,
      .address {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .orderCardContainer{
    .imgContainer{
      width: 30%;
      img{
        width: 100% !important;
        height: 30% !important;
      }
    }
    .infoContainer{
      .title{
        font-size: 16px;
      }
      .sku{
        font-size: 14px;
      }
    }
   
  }
  .orderPrice{
    font-size:18px ;
   }


}
@media screen and (max-width: 600px) {
  .orderCardContainer{
    .imgContainer{
      width: 40%;
      img{
        width: 100% !important;
        height: 30% !important;
      }
    }
    .infoContainer{
      .title{
        font-size: 14px;
      }
      .sku{
        font-size: 12px;
      }
    }
   
  }
  .orderPrice{
    font-size:14px ;
   }


}

@media screen and (max-width: 480px) {
  .backBtnOrder {
    margin-bottom: 15px !important;
  }
  .order-details-page-title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 15px;
    .orderTop {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 50vw;
      .orderBadge {
        .ant-badge-status-dot {
          height: 15px;
          width: 15px;
          padding: 0;
          margin-right: 10px;
        }
        .ant-badge-status-text {
          display: none;
        }
      }
    }
  }

  .order-details-buttons {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
    flex-direction: column;
    .theme-btn {
      width: 100%;
      font-size: 14px;
      &:nth-child(1) {
        margin-right: 0px;
      }
    }
  }
  .order-addres-bar {
    display: flex;
    flex-direction: column;
    padding: 0;
    .left {
      width: 100%;
      font-size: 16px;
      display: flex;
      gap: 5px;
      p {
        margin-bottom: 10px;
      }
    }
    .right {
      width: 100%;
      font-size: 16px;
      padding-left: 30px;
      .name {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .phone-number,
      .address {
        margin-bottom: 5px;

        font-size: 14px;
        color: #959595;
      }
    }
    margin-bottom: 15px;
  }

  .order-details-topbar {
    background-color: $bg-color;
  }
  .order-details-top {
    margin-bottom: 10px !important;
    .ant-badge {
      margin: 0;
    }
  }
  .order-details-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  .orderCardContainer {
    gap: 10px;
    .imgContainer {
      height: 100%;
      img {
        width: 80px;
        height: 60px;
        border-radius: 8px;
      }
    }
    .infoContainer {
      margin-right: 10px;
      .title {
        font-size: 16px;
        line-height: 1.2em;
        margin: 0;
      }
      .sku {
        font-size: 14px;
      }
    }
  }
  .orderPrice {
    font-size: 16px;
    color: $primary-color;
    font-weight: bold;
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}
