@import "../../../../../shared/style/Variables.scss";

.add-dealer-main-content {
  background-color: #f8f8fb !important;
  padding: 15px;
  .main-title {
    text-align: left;
    font-family: Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #222222;
    text-transform: uppercase;
    opacity: 1;
  }
  .add-dealer-icon {
    opacity: 1;
    margin-top: 1rem;
    color: #000000;
  }

  .add-dealer-title {
    text-align: left;
    font: normal normal bold 24px/29px Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .address-row-title {
    text-align: left;
    font: normal normal bold 20px/24px Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .dealer-detail-title {
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .add-dealer-form {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 3rem;

    .anticon svg {
      color: #c71e21;
      height: 1.3rem !important;
      width: 1.3rem !important;
    }

    .ant-form-item-label {
      text-align: left;
      font: normal normal normal 18px/22px Roboto;
      letter-spacing: 0px;
      color: #383838;
      margin-bottom: 0.5rem;
    }
    .ant-input-affix-wrapper {
      background-color: #f3f3f9;
      height: 3rem;
      border: 1px solid #d0d0d2;
      border-radius: 8px;
    }

    .address-text {
      height: 12rem;
    }

    .add-dealer-address-btn {
      border: 1px solid #c71e21;
      border-radius: 8px;
      text-align: left;
      font: normal normal 18px/22px Roboto;
      letter-spacing: 0.13px;
      color: #000000;
      margin-top: 2rem;
      padding: 1rem 2rem;
      height: 4rem;
      width: 14rem;
      background-color: #f3f3f9;
      margin-bottom: 1.4rem;
    }

    .ant-select-selector {
      background-color: #fff;
      height: 3rem;
      border: 1px solid #d0d0d2;
      border-radius: 8px;
      padding: 0.5rem;
    }

    .add-dealer-btn {
      border: 1px solid #c71e21;
      border-radius: 8px;
      text-align: left;
      font: normal normal 18px/22px Roboto;
      letter-spacing: 0.13px;
      color: white;
      margin-top: 2rem;
      padding: 1rem 4rem;
      height: 4rem;
      width: 14rem;
      background-color: #c71e21;
    }
  }
}

@media screen and (max-width: 768px) {
  .add-dealer-main-content {
    margin: 0 !important;
    padding: 0 !important;
  }

  .dealer-detail-title,
  .add-dealer-form {
    margin: 0 !important;
    padding: 0px 15px !important;
  }
  .dealer-detail-title {
    margin-bottom: 15px !important;
  }
  .add-dealer-main-content .add-dealer-form .add-dealer-address-btn {
    padding: 15px;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    width: fit-content;
    height: fit-content;
    margin-left: 20px;
  }
}

@media screen and (max-width: 480px) {
  .add-dealer-main-content,
  .dealer-detail-title,
  .add-dealer-form {
    margin: 0 !important;
    padding: 0 !important;
  }
  .dealer-detail-title {
    margin-bottom: 15px !important;
  }
  .add-dealer-main-content .add-dealer-form .add-dealer-address-btn {
    padding: 10px;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
    width: fit-content;
    height: fit-content;
  }
}
