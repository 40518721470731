@import "../../../../shared/style/Variables.scss";

.Notification-dropdown-content {
  overflow: hidden;
  background-color: #fff;
  outline: 1px solid #ccc;
  border-radius: 6px;
  max-width: 340px;
  min-width: 320px;
  margin-top: 10px;
  .notification-detail-card {
    cursor: pointer;
    display: flex;
    gap: 10px;
    margin: 0;
    overflow: hidden;

    .details {
      padding: 0;
      overflow: hidden;
      width: 100%;
    }
    .image {
      position: relative;
      height: 100%;
      img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 4px;
      }
    }

    &:not(first-child) {
      margin-top: 8px;
    }
  }

  .notification-card-modal-title {
    font-size: 1rem;
    line-height: 1.2em;
    font-weight: bold !important;
    font-weight: 500;
    margin-bottom: 2px !important;
  }
  .notification-card-modal-info {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .notification-detail-card-modal {
    padding: 10px !important;
    margin-bottom: 0 !important;
  }
  .notification-card-modal-time-tag {
    display: inline-block;
    padding: 5px;
    border-radius: 8px;
    background-color: $bg-color;
    font-size: 12px;
  }
}

.view-notification-modal {
  margin: 0;
  border-top: 1px solid transparent;
  padding: 10px;
}
.noUnreadNotifications {
  text-align: center;
  margin: 0;
  padding: 15px;
  font-size: 1.2rem;
}
