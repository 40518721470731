@import "../../../../../shared/style/Variables.scss";

.home-main-content-layout {
  padding: 50px;
  background-color: white;

  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }

  @media screen and (max-width: 720px) {
    padding-top: 20px;
  }
}

.content {
  background-color: white;

  .header-logo-wrapper {
    width: 270px;

    .dash-btn {
      border-radius: 5px;
      align-items: center;
    }
  }

  // .featured-photo
  .photo {
    max-width: 100%;
    height: auto;
  }

  .category {
    .photo {
      height: 196px !important;
      width: 100% !important;

      @media screen and (max-width: 1440px) {
        border-radius: 20px;
        width: 100% !important;
        height: 150px !important;
        object-fit: cover;
      }
      @media screen and (max-width: 1280px) {
        height: 125px !important;
      }
      @media screen and (max-width: 1024px) {
        border-radius: 20px;
        width: 100% !important;
        height: 95px !important;
        object-fit: cover;
      }

      @media screen and (max-width: 768px) {
        width: 110px !important;
        height: 110px !important;
      }

      @media screen and (max-width: 480px) {
        width: 91px !important;
        height: 91px !important;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .header-logo-wrapper {
      width: 168px;
    }
  }

  .carousel {
    margin-top: 20px;

    .photo {
      width: 100vw;
      padding: 20px;
      height: 455px;
      padding: 0 20px;
      border-radius: 50px;
      object-fit: cover;

      // @media screen and (min-width: 1023px) and (max-width: 1349px) {
      //   width: 900px;
      //   padding: 10px;
      //   // height: 500px;
      // }
    }

    // .slick-track {
    //   @media screen and (min-width: 1350px) and (max-width: 1442px) {
    //     height: 350px;
    //   }
    // }

    // .slick-track {
    //   @media screen and (min-width: 1023px) and (max-width: 1349px) {
    //     width: 80%;
    //     padding: 10px;
    //     height: 300px;
    //   }
    // }
  }

  .category {
    // padding: 50px 30px;
    .category-title {
      font-family: Roboto;
      font-weight: bold;
      font-size: 30px;
      letter-spacing: 13;
      padding-bottom: 20px;

      a {
        display: none;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
        font-weight: bolder;
        display: flex;
        justify-content: space-between;
        a {
          display: inline;
          color: $primary-color;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    .category-photo {
      // .ant-carousel {
      //     .slick-slider{
      //         .slick-list{
      //             .slick-track{
      //                 // transform: translate3d(0px, 0px, 0px) !important;
      //                 // touch-action: pan-x;
      //             }
      //         }
      //     }
      // }

      div {
        .slick-slide {
          @media screen and (min-width: 320px) and (max-width: 480px) {
            width: 100px !important;
          }
        }
      }

      .ant-carousel {
        .slick-list {
          @media screen and (min-width: 320px) and (max-width: 480px) {
            height: 150px !important;
          }
        }
      }

      figure {
        @media screen and (max-width: 480px) {
          .img-text {
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }

    .category-btn {
      margin: 5px;
      border-color: #034ea2;
    }

    .img-text {
      color: black;
      text-align: center;
      font-weight: bold;
      font-size: 1.1rem;
      margin-top: 5px;
    }

    .photo {
      border-radius: 20px;
    }

    p {
      color: #034ea2;
    }
  }

  .featured {
    padding-bottom: 40px;
    .category-title {
      a {
        display: none;
      }
    }
    .featured-card-main {
      border: 1px solid #dedede;
      opacity: 1;
      height: 100%;
      width: 100%;
      border-radius: 18px;

      @media screen and (max-width: 480px) and (min-width: 320px) {
        //    display: none;
        border-radius: 12px !important;
        height: 100% !important;
      }
    }

    .slick-dots {
      position: absolute;
      height: fit-content;
      top: -50px;
      right: 6%;
      display: flex;
      margin: 0;
      justify-content: end;

      li.slick-active button {
        background-color: $primary-color;
        width: 15x;
        height: 15px;
        border: none;
        border-radius: 50%;
      }
    }

    .slick-dots li {
      background-color: #d0d0d2;
      width: 15px !important;
      height: 15px;
      border: none;
      border-radius: 50%;

      button {
        width: 15px !important;
        height: 15px !important;
      }
    }

    .featuredCardLeft {
      border-radius: 32px;
      padding: 60px 45px;
      height: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      div {
        div {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      .discountPercent {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.2em;
      }

      img {
        margin: 0 auto;
        width: 100%;
        height: 230px;
        object-fit: contain;
      }

      .featured-timer {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        height: 100%;

        .featured-hour,
        .featured-min,
        .featured-sec {
          border: 1px solid rgba(255, 255, 255, 0.8);
          padding: 15px;
          border-radius: 15px;

          div {
            text-align: center;

            b {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .featured-title-row {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .featured-row {
      position: relative;
      display: flex;

      .featured-carousal {
        @media screen and (max-width: 480px) and (min-width: 320px) {
          // width: 100px;
          // height: 80px;
          // .slick-slide {
          //   width: 135px !important;
          // }
        }

        .featured-card {
          position: relative;
          .offer-line {
            position: absolute;
            right: 0;
            top: 0;
            background-color: $primary-color;
            border-bottom-left-radius: 100vw;
            // border-bottom-right-radius: 15px;
            .product-detail-offer-text {
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 30px;
              padding-right: 15px;
              color: $white;
              margin: 0;
            }
          }
          // .product-details {
          //   @media screen and (max-width: 480px) and (min-width: 320px) {
          //     .inventory-info {
          //       padding: 0 4px !important;
          //     }
          //   }

          .AddToCartBtnContainer {
            // width: 320px !important;

            @media screen and (max-width: 320px) {
              width: 95% !important;
              margin: 0 auto;
              padding-bottom: 7px !important;

              .addToCartBtn {
                height: 30px;
                border-radius: 5px;
                // width: 125px !important;
              }

              svg {
                width: 0.5em;
              }

              span {
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }

    .photo {
      width: 100%;
      height: auto;
    }
  }

  .featured-title {
    font-family: Roboto;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 13;
  }
}
.view-popular {
  a {
    color: $primary-color;
    padding-right: 17px;
    font-size: 15px;
  }
}
.popular {
  .ant-row {
    flex-flow: wrap !important;
  }

  .popular-row {
    display: flex;
    flex-wrap: wrap;
  }

  .photo {
    border-radius: 10px;
  }

  .popular-title {
    font-family: Roboto;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 13;
    // padding-top: 30px;
    padding-bottom: 30px;
  }

  .view-popular {
    a {
      color: $primary-color;
      padding-right: 17px;
      font-size: 15px;
    }
  }

  .popular-heading {
    text-align: left;
    font-family: Roboto;
    font-weight: 24px;

    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }

  .popular-text {
    color: $primary-color;
    font-weight: bold;
  }

  .add-btn {
    width: 200px;
    border-radius: 10px;
  }

  .productCardMain {
    border: 1px solid #dedede;
    opacity: 1;
    height: fit-content;

    width: 97%;
    padding: 14px;

    .AddToCartBtnContainer {
      .addToCartBtn {
        width: 100%;
      }
    }

    @media screen and (max-width: 586px) {
      border-radius: 11px;
      height: fit-content;
      gap: 5px;
      margin-left: 4px;
      .addToCartBtn {
        width: 100%;
        .icon {
          svg {
            height: 10px !important;
            width: 10px !important;
          }
        }
        span:last-child {
          margin: 0 !important;
        }
      }
    }

    .product-image {
      img {
        height: 400px;
        width: 100%;
        object-fit: fill;

        @media screen and (max-width: 1446px) {
          height: 300px;

          width: 100% !important;
        }
        @media screen and (max-width: 1280px) {
          height: 260px;
        }
        @media screen and (min-width: 425px) and (max-width: 480px) {
          height: 140px;
          width: 100% !important;
          border-radius: 11px;
        }

        @media screen and (min-width: 370px) and (max-width: 424px) {
          height: 140px;
          width: 100% !important;
          border-radius: 11px;
        }

        @media screen and (min-width: 320px) and (max-width: 370px) {
          height: 130px;
          width: 100% !important;
          border-radius: 11px;
        }
      }
    }

    .product-details {
      .title {
        font-size: 22px !important;

        @media screen and (max-width: 480px) {
          font-size: 14px !important;
          padding-top: 2px;
          /* min-height: 47px; */
          padding-bottom: 1px;
          max-height: 47px;
          overflow: hidden;
        }
      }

      .price {
        @media screen and (max-width: 480px) {
          font-size: 16px !important;
          line-height: 1.2rem !important;
          margin-top: 5px;
          margin-bottom: 10px;
        }

        @media screen and (min-width: 320px) and (max-width: 370px) {
          font-size: 11px !important;
          margin-top: 0px;
          line-height: 0.8rem !important;
        }
      }

      .inventory-info {
        p {
          @media screen and (min-width: 320px) and (max-width: 480px) {
            font-size: 12px !important;
            margin-bottom: 5px;
            line-height: 9px;
          }

          @media screen and (min-width: 320px) and (max-width: 370px) {
            font-size: 12px !important;
            // margin-bottom: 0px;
            line-height: 9px;
          }
        }
      }
    }
  }

  // width: 300px;

  // height: 100vh;
  .AddToCartBtnContainer {
    // padding: 0 !important;
    // width: 300px !important;
    display: flex;
    justify-content: space-between;
    gap: 15px;

    @media screen and (min-width: 320px) and (max-width: 480px) {
      width: 100% !important;
      justify-content: space-between;
      gap: 5px;
    }

    .addToCartBtn {
      @media screen and (min-width: 320px) and (max-width: 370px) {
        width: 60px !important;
        height: 25px;
        border-radius: 4px;

        svg {
          width: 9px;
          height: auto;
        }

        // border-radius: 2px;
        span {
          font-size: 8px;
        }
      }

      @media screen and (min-width: 370px) and (max-width: 424px) {
        width: 80px !important;
        height: 25px;
        border-radius: 4px;

        svg {
          width: 9px;
          height: auto;
        }

        // border-radius: 2px;
        span {
          font-size: 8px;
        }
      }

      @media screen and (max-width: 480px) {
        width: 100% !important;
        height: 30px;
        border-radius: 4px;

        svg {
          width: 9px;
          height: auto;
        }

        span {
          font-size: 10px;
        }
      }

      @media screen and (max-width: 360px) {
        width: 70% !important;
        height: 30px;
        border-radius: 4px;
      }
    }

    .wishlistBtn {
      // height: 67px;
      // width: 87px;
      // display: flex;
      border: 1px solid #034ea2 !important;
      color: #034ea2;

      @media screen and (min-width: 320px) and (max-width: 370px) {
        display: flex;
        width: 15px !important;
        border-radius: 4px;
        height: auto;
        justify-content: center;
        align-items: center;

        .anticon-heart {
          svg {
            width: 0.8em !important;
            height: 0.8em !important;
          }
        }
      }

      @media screen and (min-width: 370px) and (max-width: 424px) {
        display: flex;
        width: 20px !important;
        border-radius: 4px;
        height: auto;
        justify-content: center;
        align-items: center;

        .anticon-heart {
          svg {
            width: 0.7em !important;
            height: 0.7em !important;
          }
        }
      }

      @media screen and (min-width: 424px) and (max-width: 450px) {
        display: flex;
        width: 20px !important;
        border-radius: 4px;
        height: auto;
        justify-content: center;
        align-items: center;

        .anticon-heart {
          svg {
            width: 0.9em !important;
            height: 0.9em !important;
          }
        }
      }

      @media screen and (min-width: 450px) and (max-width: 480px) {
        display: flex;
        width: 20px !important;
        border-radius: 4px;
        height: auto;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // .product-image {
  //     width: 100%;

  //     img {
  //         border-radius: 21px;
  //         width: 100%;
  //         object-fit: cover;
  //     }

  // }

  // img{
  //     width: 99%;
  // }
  // }
}

.long-img {
  padding-top: 30px;
  padding-bottom: 30px;

  img {
    width: 100%;
    border-radius: 30px;
  }
}

.short-img {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  .short-img-photo {
    width: 100%;
    border-radius: 25px;
  }

  .ant-row {
    flex-flow: nowrap;
  }

  @media screen and (min-width: 300px) and (max-width: 768px) {
    display: flex;
    height: 100%;
  }
}

.popular-seller {
  // padding:50px;

  .popular-seller-title {
    font-family: Roboto;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 13;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ant-carousel {
    .slick-slider {
      .slick-list {
        .slick-track {
          transform: translate3d(0px, 0px, 0px) !important;
        }
      }
    }
  }

  .card-box {
    margin: 20px;
    min-width: 200px;
    border-radius: 20px;
    background-color: #efefef;

    .img-text {
      text-align: center;
      letter-spacing: 0.27px;
      color: #000000;
      font-weight: bold;
      font: normal normal bold 21px Roboto;
      opacity: 1;

      @media screen and (min-width: 768px) and (max-width: 1025px) {
        font-size: 12px;
      }

      @media screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 12px;
      }
    }

    .quantity-text {
      text-align: center;
      font: normal normal 300 11px Roboto;
      letter-spacing: 0.14px;
      color: #000000;

      @media screen and (min-width: 768px) and (max-width: 1025px) {
        font-size: 7px;
      }

      @media screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 8px;
      }
    }

    .photo {
      height: 70%;
      border-radius: 20px;
    }

    .popular-icon {
      position: absolute;
      top: 55%;
      left: 45%;
      font-size: 40px;
      height: 64px !important;
      width: 63px !important;

      @media screen and (min-width: 768px) and (max-width: 1025px) {
        top: 40%;
        left: 40%;
      }
    }
  }
}

.featured-card {
  border: 1px solid #dedede;
  opacity: 1;
  height: auto;
  width: 95%;

  .featured-image {
    width: 100%;

    img {
      height: 335px;
      width: 100%;
      object-fit: contain;
      @media screen and (max-width: 1280px) {
        height: 275px;
      }
      @media screen and (max-width: 480px) {
        height: 140px !important;
        min-height: 0;
      }
    }
  }

  .cancel-price {
    text-decoration-line: line-through;
    font-size: 24px !important;
    color: #a7a7a7;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .new-price {
    padding-left: 5px;
    font-size: 24px !important;
    color: $primary-color;
    font-weight: 500;
    margin-bottom: 15px;
    span {
      font-size: 17px;
    }

    @media screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 10px !important;
    }
  }

  .cancel-price,
  .new-price {
    @media screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 12px !important;
      margin-top: 5px;
      line-height: 1em !important;
    }
  }

  p {
    @media screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 12px !important;
      line-height: 0.5rem !important;
    }
  }

  .featured-p {
    padding: 30px;
    margin: auto;
    text-align: center;
    font: normal normal normal 22px Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    // max-height: 114px;
    // min-height: 114px;

    @media screen and (max-width: 480px) {
      font: normal normal normal 16px Roboto;
      padding: 10px;
    }
  }

  .product-details {
    .title {
      padding: 15px 0 20px;
      font-size: 24px;
      margin: 0;
    }
    .price {
      font-size: 24px !important;
      color: $primary-color;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .inventory-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      @media screen and (max-width: 500px) {
        // flex-direction: revert !important;
        padding: 5px 10px;
      }
      div {
        display: flex !important;
        gap: 5px !important;
        p:nth-child(1) {
          font-size: 16px;
          color: #383838;
          margin-bottom: 2px;
        }

        p:nth-child(2) {
          font-size: 18px;
        }
      }
    }

    .AddToCartBtnContainer {
      display: flex !important;
      justify-content: center !important;
      // align-items: center !important;
      padding-bottom: 15px;

      button {
        width: 90%;

        @media screen and (max-width: 1024px) and (min-width: 786) {
          max-width: 90%;
        }

        @media screen and (max-width: 768px) {
          width: 90% !important;
        }

        padding: 2px;
        color: white;
      }
    }
  }
}

.banner {
  margin-top: 40px;
  position: relative;

  @media screen and (max-width: 786px) {
    height: 200px !important;
  }

  @media screen and (max-width: 1024px) {
    height: 400px !important;
  }

  .banner-photo {
    width: 100%;
    height: 585px;
    object-fit: cover;

    @media screen and (max-width: 786px) {
      height: 200px !important;
      object-fit: cover;
    }

    @media screen and (max-width: 1024px) {
      height: 400px !important;
      object-fit: cover;
    }
  }

  .banner-text {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0%;

    @media screen and (min-width: 1024px) {
      height: 100%;
      max-width: 50%;
    }

    @media screen and (max-width: 425px) {
      max-height: 50%;
      width: 100%;
    }

    display: flex;
    align-items: center;
    padding: 5px;
  }

  .banner-inner-text {
    width: 60%;
    padding: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    h1 {
      text-align: left;
      font: normal normal bold 58px/70px Roboto;
      letter-spacing: 0.75px;
      color: #ffffff;
      opacity: 1;

      @media screen and (min-width: 1024px) and(max-width: 1260px) {
        font-size: 28px;
        line-height: 2rem;
      }

      @media screen and (min-width: 1261px) and(max-width: 1352px) {
        font-size: 30px;
        line-height: 2rem;
      }

      @media screen and (max-width: 426px) and(min-width: 375px) {
        font-size: 24px;
      }

      @media screen and (max-width: 376px) and(min-width: 321px) {
        font-size: 22px;
      }

      @media screen and (max-width: 321px) {
        font-size: 20px;
      }
    }

    p {
      text-align: left;
      font: normal normal normal 24px/29px Roboto;
      letter-spacing: 0.14px;
      color: #ffffff;
      opacity: 1;

      @media screen and (min-width: 1024px) and(max-width: 1260px) {
        font-size: 18px;
        line-height: 1.5rem;
      }

      @media screen and (min-width: 1261px) and(max-width: 1352px) {
        font-size: 20px;
        line-height: 1.5rem;
      }

      @media screen and (max-width: 426px) and(min-width: 375px) {
        font-size: 14px;
      }

      @media screen and (max-width: 376px) and(min-width: 321px) {
        font-size: 12px;
      }

      @media screen and (max-width: 321px) {
        font-size: 10px;
      }
    }

    .banner-btn {
      text-align: left;
      display: flex;
      align-items: center;
      font: normal normal bold 19px/23px Roboto;
      letter-spacing: 0.25px;
      background-color: transparent;
      padding-left: 0px;
      color: white;
      opacity: 1;

      @media screen and (min-width: 1024px) and(max-width: 1260px) {
        text-align: left;
        font-size: 12px;
      }

      @media screen and (min-width: 1261px) and(max-width: 1352px) {
        font-size: 14px;
      }

      @media screen and (max-width: 426px) and(min-width: 375px) {
        font-size: 11px;
      }

      @media screen and (max-width: 376px) and(min-width: 321px) {
        font-size: 10px;
      }

      @media screen and (max-width: 321px) {
        font-size: 8px;
      }
    }

    @media screen and (max-width: 426px) and(min-width: 375px) {
      width: 100%;
      height: 50%;
      padding: 20px;
    }

    @media screen and (max-width: 376px) and(min-width: 321px) {
      width: 100%;
      height: 50%;
      padding: 10px;
    }

    @media screen and (max-width: 321px) {
      width: 100%;
      height: 50%;
      padding: 7px;
    }

    .banner-photo {
      max-width: 100%;
      height: auto;
    }
  }

  .photo {
    max-width: 100%;
  }
}

@media screen and (max-width: 720px) {
  .featured-title {
    font-size: 18px !important;
  }

  .featured-title-row {
    padding-top: 0px !important;
  }

  .popular-title {
    font-size: 18px !important;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .popular-seller-title {
    font-size: 18px !important;
    padding-bottom: 5px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .content .popular .productCardMain .product-image img {
    height: 340px;
  }

  .content .carousel .photo {
    height: 500px;
  }
  .long-img {
    padding-top: 15px;
    padding-bottom: 15px;
    .photo {
      object-fit: contain;
      border-radius: 25px;
    }
  }

  .short-img {
    .short-img-photo {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-main-content-layout {
    padding: 1rem;
  }

  .content .carousel .photo {
    height: 320px;
    padding: 5px;
    border-radius: 20px;
  }

  .browerCategoryFigure {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .long-img {
    padding-top: 15px;
    padding-bottom: 15px;
    .photo {
      object-fit: contain;
      border-radius: 25px;
    }
  }

  .short-img {
    .short-img-photo {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .content .featured {
    padding: 0 !important;
  }

  .content .carousel .photo {
    height: 160px;
    padding: 5px;
    border-radius: 20px;
  }

  .home-main-content-layout {
    padding: 15px;
  }

  .productCardMain {
    border-radius: 11px;
    margin: 0 !important;
    padding: 10px !important;
  }

  .content .popular .popular-title {
    padding: 30px 0 20px;
  }

  .long-img {
    padding-top: 30px;
    padding-bottom: 15px;
    .photo {
      object-fit: contain;
      border-radius: 25px;
    }
  }

  .short-img {
    flex-direction: column;

    .short-img-photo {
      width: 100% !important;
    }
  }

  .content .featured .featured-title-row {
    padding-bottom: 15px;
  }

  .content .featured .slick-dots {
    top: -30px;
  }

  .content .featured .slick-dots li {
    height: 8px;
    width: 8px !important;

    button {
      height: 8px !important;
      width: 8px !important;
    }
  }

  .featuredCardLeft {
    border-radius: 20px !important;
    padding: 20px 5px !important;
    color: #fff;

    .discountPercent {
      font-size: 1rem !important;
    }

    img {
      margin: 0 auto;
      width: 100%;
      height: 80px !important;
    }

    .featured-timer {
      display: flex;
      justify-content: space-between !important;

      .featured-hour,
      .featured-min,
      .featured-sec {
        padding: 3px !important;
        border-radius: 5px !important;

        div {
          b {
            font-size: 0.7rem !important;
          }

          font-size: 0.5rem !important;
        }
      }
    }
  }
}

.bottomBanner {
  margin-top: 40px;

  .bottomBannerLeft {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;

    img {
      width: 100%;
    }
  }

  .bottomBannerLeft {
    position: relative;
    z-index: 1;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: rgba(0, 0, 0, 0.8);
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: transparent;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 45%;
      padding-left: 5%;
      .title,
      .descrip,
      button {
        text-align: left;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        color: $white;
        background-color: transparent;
        position: relative;
      }

      .title {
        font-size: 3rem;
        margin-bottom: 20px;
      }

      p.descrip {
        font-size: 1.2rem;
        margin-bottom: 40px;
        line-height: 1.2rem;
      }

      button {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .bottomBanner {
    margin-top: 40px;

    .bottomBannerLeft {
      height: 350px;
      .content {
        .title {
          font-size: 2rem;
          margin-bottom: 10px;
        }

        p.descrip {
          font-size: 1rem;
          margin-bottom: 20px;
        }

        button {
          display: flex;
          align-items: center;
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .featured-card {
    width: 100%;
    .new-price {
      span {
        font-size: 14px;
      }
    }
  }
  .bottomBanner {
    margin-top: 40px;

    .bottomBannerLeft {
      height: 150px;
    }
  }
}

@media screen and (max-width: 360px) {
  .product-details .inventory-info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .content .featured .slick-dots {
    top: -19px;
  }
}

.home-long-img {
  width: 100%;
  cursor: pointer;
}

.bannerVideoDiv {
  position: relative;
  button {
    position: absolute;
    right: 5%;
    top: 5%;
    z-index: 99999;
    background-color: $primary-color;
    border: 1px solid white;
    color: white;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
  }
  video {
    cursor: "auto";
    aspect-ratio: 16/9;
  }
}
.padding-0 {
  padding: 0 !important;
}
