.ant-table-filter-column {
    justify-content: center !important;
    // flex: 1 0;
  } 
  .ant-table-column-title {
  flex: none;
  text-align: left;
  font: normal normal  16px/19px Roboto !important;
  letter-spacing: 1.16px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  // text-decoration: none !important;
  }

   .ant-table tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    white-space: normal;
  }


.actionBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.product-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}