@import "../../../../shared/style/Variables.scss";

.wishlistCard {
  width: 390px;
  .product-image {
    img {
      width: 100%;
    }
  }
}

.wishlist-card-image {
  img {
    height: 171px !important;
    width: 256px !important;
  }
}
.checkout-product-card {
  width: 100% !important;
}
.checkout-card-quantity {
  justify-content: start !important;
}
.wishlist-card-cancel {
  display: flex;
  gap: 15px;
  // justify-content: flex-start;

  button {
    color: black;
    height: 100%;
    gap: 15px;
    color: $primary-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid $border-color;
    padding: 15px 20px;
    font-size: 18px;
  }
  button:nth-child(1) {
    flex-grow: 1;
    background-color: $primary-color;
    color: white;
  }
  button:last-child {
    svg {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .wishlistCard {
    max-width: 290px;
    .product-image {
      img {
        width: 100% !important;
      }
    }
  }

  .wishlist-card-cancel {
    button {
      font-size: 16px;
      padding: 13px 18px;
    }
    button:nth-child(1) {
      flex-grow: 1;
      background-color: $primary-color;
      color: white;
    }
    button:last-child {
      svg {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .wishlistCard {
    max-width: 100%;
    .product-image {
      img {
        width: 100% !important;
      }
    }
  }
  .wishlist-card-iventory {
    display: flex;
    flex-direction: row !important;
  }
  .wishlist-card-cancel {
    gap: 10px;
    margin-top: 15px;

    button {
      gap: 10px;
      border: 1px solid $border-color;
      padding: 12px 10px;
      font-size: 16px;
    }
    button:nth-child(1) {
      flex-grow: 1;
      background-color: $primary-color;
      color: white;
    }
    button:last-child {
      svg {
        font-size: 22px;
      }
    }
  }
}
// @media screen and (max-width: 700px) {
//   .cart-product-card {
//     padding-bottom: 15px;
//     padding-top: 15px;
//     flex-direction: column;
//     gap: 5px;
//     // .checkout-card-image {
//     //   order: 2 !important;
//     // }
//     .checkout-card-cancel {
//       order: 4 !important;
//       flex-direction: row;
//       .price {
//         font-size: 28px !important;
//       }
//     }
//     .checkout-card-details {
//       .sku {
//         justify-content: space-between !important;
//         margin-bottom: 10px;
//         font-size: 14px;
//       }
//     }

//     .cancel {
//       .price {
//         font-size: 18px;
//         margin: 0;
//       }
//     }
//   }
// }

// .wishlist-card {
//   border-color: #676767;
// }

// .wishlist-card:last-child {
//   border: none;
// }

// .wishlist-card-image {
//   flex: 0.25 !important;
// }

// .wishlist-card-details {
//   flex: 0.5 !important;
// }

// .wishlist-card-cancel {
//   flex-direction: row !important;
//   align-items: center;
//   justify-content: flex-end !important;
//   gap: 25px;

//   .theme-btn1 {
//     color: #343434;

//     .icon {
//       height: 22px;
//     }
//   }

//   .theme-btn1:nth-child(1) {
//     min-width: 216px;
//     min-height: 64px;
//   }

//   .theme-btn1:last-child {
//     border-radius: 100%;
//     border-color: #000000 !important;
//     padding: 10px 4px !important;
//   }
// }

// @media screen and (max-width: 768px) {
//   .wishlist-card-image {
//     img {
//       width: 150px !important;
//       height: 100px !important;
//     }
//   }

//   .wishlist-card-details {
//     flex: 0.75 !important;
//   }

//   .wishlist-card-cancel {
//     justify-content: space-between !important;
//     gap: 25px;

//     .theme-btn1:nth-child(1) {
//       min-width: 170px;
//       min-height: 54px;
//     }
//   }
// }

// @media screen and (max-width: 480px) {
//   .wishlist-card-image {
//     img {
//       width: 196px !important;
//       height: 140px !important;
//     }
//   }
// }
