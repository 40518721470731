@import "../../../style/Variables.scss";

.admin-list-layout {
  .title-bar {
    padding: 15px;

    .icon {
      svg {
        font-size: 30px;
      }
    }

    .ant-typography {
      margin: 0;
    }
  }
}

.manage-order-title {
  font-size: 1.3rem;
}
.admin-footer {
  padding: 20px !important;
  display: flex;
  justify-content: space-between;
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    gap: 10px;
    li {
      list-style: none;
      a {
        cursor: pointer;
        font-size: 16px;
        color: #363636;
      }
    }
  }
}
.roleNameStyle {
  font-size: 18px;
  background-color: #3b3b3bc9;
  padding: 0 15px;
  color: white;
}
.floatingCartBtn {
  background-color: $primary-color;
  color: $white;
  height: fit-content;
  position: fixed;
  right: 2%;
  bottom: 5%;
  z-index: 999;
  padding: 14px 18px;
  border-radius: 100vw;
  border: 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.15);
  }
}
.floatingCartBadge {
  .floatingCartIcon {
    color: $white;
    svg {
      height: 2.1rem !important;
      width: 2.1rem !important;
    }
  }
  &:hover {
    color: $primary-color;
  }
  .ant-badge-count {
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $primary-color;
    border-color: $primary-color;
    border: 1px solid;
    font-size: 1.4rem;
  }
}
.roleName {
  display: none;
}
@media screen and (max-width: 480px) {
  .roleNameStyle {
    display: none;
  }
  .roleName {
    display: inline-block;
    line-height: 1rem;
    text-align: center;
    color: white;
    background-color: #3b3b3bc9;
    padding: 5px;
  }
  .roleNameStyle {
    font-size: 16px;
    padding: 0 5px;
  }
  .floatingCartBtn {
    position: fixed;
    right: 5%;
    bottom: 6%;
    z-index: 999;
  }
  .floatingCartBadge {
    .floatingCartIcon {
      svg {
        height: 1.6rem !important;
        width: 1.6rem !important;
      }
    }
    .ant-badge-count {
      font-size: 1.2rem;
    }
  }
  .ant-notification-bottomLeft {
    right: 0px !important;
    top: auto !important;
    bottom: 14% !important;
    font-size: 15px !important;
  }
}
