@import "../../../../../shared/style/Variables.scss";

.adminLeftArrowIcon {
  cursor: pointer;

  .anticon svg {
    font-size: 1.6rem;
  }
}

.adminInputs {
  background-color: #f3f3f9;
  height: 3rem;
}

.adminTextarea {
  background-color: #f3f3f9;
  resize: none;
}

.existingRolesContainer {
  border-radius: 5px;
  border: 1px solid lightgray;
  overflow-y: auto;

  .existingRole {
    p {
      margin: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }
}

.addRoleRightHeading {
  background-color: $primary-color;
  color: $white;
  padding: 10px 20px;
  font-size: 1rem;
}

[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.site-collapse-custom-panel .ant-collapse-header {
  border: 1px solid black;
}

.addRolesBorder {
}

.addRoleLeft {
  border-right: 1px solid lightgray;
}

.addRoleRight {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .addRoleRight {
    padding: 30px 0px;
  }
}

.permissionTable {
  .ant-table-thead .ant-table-cell {
    background-color: transparent !important;
    color: #000 !important;
    text-align: center;
  }

  .ant-table thead > tr > th,
  .ant-table tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    text-align: center;
  }
}

.role_list_action {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
