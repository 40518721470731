.new-notification-btn {
  height: 95% !important;
}
.send-notification-btn {
  height: 3rem;
  width: 12rem;
  margin-left: 7.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  border-radius: 8px;
  background-color: #c71e21;
  color: white;
  font-size: 1rem;
}

@media screen and (max-width: 480px) {
  .add-user-main-content {
    padding: 0;
  }
}
