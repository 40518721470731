@import "../../../../../shared/style/Variables.scss";

.admin-profile-main-title-row{
    padding: 0.5rem 2rem !important;
    .changePass-btn{
      height: 3rem;
      width:10rem;
      border-radius: 8px;
      border-color: $primary-color !important ;
      // background-color: white !important;
      // color: $primary-color !important  ;
     }
  
}
.admin-profile-main-title-row-2{
  padding: 0rem 3rem !important;
  margin-bottom: 2rem !important;

}

.admin-profile-main-title{
    text-align: left;
font-weight: 500;
font-size: 16px;
font-family: Roboto;
letter-spacing: 0px;
color: #222222;
text-transform: uppercase;
opacity: 1;

}

.admin-profile-container {
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    justify-content: center;
  }
}
.admin-profile-form {
  width: 60%;
  .ant-input{
    background-color: white !important;
  }
  .ant-input-affix-wrapper-disabled {
    background-color:#F0F0F5 !important; 
    .ant-input{
      background-color: #F0F0F5 !important;
    }
}
  .modalInputs{
    height: 4rem;
  }
  .btn-center{
    display: flex;
    justify-content: center;
  }
  .resetPasswordLink{
    color: $primary-color;
    display: flex;
    justify-content: center;
    font-size: 1rem;
  }

}

.admin-reset-form {
  width: 30%;
  text-align: center;
  justify-content: center;
  display: flex;
  margin: auto;
  .reset-btn-admin{
    height: 3rem;
    width: 20rem;
    color: white;
    background-color: #C71E21;
    border-radius: 8px;
    font-size: 1rem;
    // font-weight: bold;

  }
  // .reset-form-admin-input{
  //   width: 25rem;
  //   text-align: center;
  //   // justify-content: center;
  //   // display: flex;

  // }
  .ant-input{
    background-color: white !important;
  }
  .modalInputs{
    height: 3rem;
  }
  .btn-center{
    display: flex;
    justify-content: center;
  }
  
  .resetBtn{
    width:100% !important;
  }
  // .anticon svg{
  //   height: 1rem !important;
  //   width: 1rem !important;
  // }
  

}


.admin-pic {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  object-fit: cover;
}
.admin-pic-text {
  font-weight: bold;
}
.fileUploadDesign {
  display: flex;
  justify-content: space-between;
  color: #a7a7a7;
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  div {
    padding: 9px 15px;
    img {
      margin-right: 8px;
    }
  }

  .text {
    margin: 0;
    padding: 9px 15px;
    height: 100%;
    border: none;
    color: #a7a7a7;
    border-left: 1px solid $border-color;
  }
}
.admin-radio-button {
  padding: 10px 20px;
  border: 1px solid #d0d0d2;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  label {
    margin-top: 10px !important;
  }
}

.handleUploadCss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #e4e4ed;
  padding: 5px 15px;
  span {
    display: inline-flex;
    color: $primary-color;
    cursor: pointer;
  }
}
@media screen and (max-width: 1024px) {
  .admin-profile-form {
    width: 100%;
  }
  .admin-radio-button {
    padding: 5px 10px;
  }
}
@media screen and(max-width:768px) {
  .admin-reset-form {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .admin-profile-form {
    width: 100%;
  }
  .reset-btn-admin{
    width: 10rem !important;
  }
  .admin-reset-form {
    width: 100%;
  }
  .admin-radio-button {
    padding: 15px;
    flex-direction: column;
  }
}
.ant-upload.ant-upload-select {
  display: block;
}
.LastUploadedFileCss{
  display: flex;
  justify-content: space-between;
  a{
    display: flex;
    gap: 5px;
    color: $primary-color;
  }
}