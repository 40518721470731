@import "../../../../../shared/style/Variables.scss";

.dealer-profile-container {
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    justify-content: center;
  }
}
.dealer-profile-form {
  width: 60%;
}

.dealer-pic {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  object-fit: cover;
}
.icon-profile {
  color: $primary-color;
}
.dealer-pic-text {
  font-weight: bold;
}
.fileUploadDesign {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  color: #a7a7a7;
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  div {
    padding: 9px 15px;
    img {
      margin-right: 8px;
    }
  }

  .text {
    margin: 0;
    padding: 9px 15px;
    height: 100%;
    border: none;
    color: #a7a7a7;
    border-left: 1px solid $border-color;
  }
}
.dealer-radio-button {
  padding: 10px 20px;
  border: 1px solid #d0d0d2;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  label {
    margin-top: 10px !important;
  }
}

.handleUploadCss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: #e4e4ed;
  padding: 5px 15px;
  span {
    display: inline-flex;
    color: $primary-color;
    cursor: pointer;
  }
}
@media screen and (max-width: 1024px) {
  .dealer-profile-form {
    width: 100%;
  }
  .dealer-radio-button {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 480px) {
  .dealer-profile-form {
    width: 100%;
  }
  .dealer-radio-button {
    padding: 15px;
    flex-direction: column;
  }
}
.ant-upload.ant-upload-select {
  display: block;
}
.LastUploadedFileCss {
  display: flex;
  justify-content: space-between;
  a {
    display: flex;
    gap: 5px;
    color: $primary-color;
  }
}

.dealerProfileLabelsSelect {
  .ant-select-arrow {
    position: absolute !important;
    left: 4% !important;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      margin-left: 9% !important;
    }
    .ant-select-selection-search {
      display: flex;
      input {
        height: 100% !important;
        margin-left: 8% !important;
        text-indent: 10px !important;
      }
    }
    .ant-select-selection-placeholder {
      padding-left: 10% !important;
    }
  }
}
.resetPasswordLink {
  color: $primary-color;
  display: flex;
  justify-content: center;
  font-size: 1rem;
}
.reset-password-main-div {
  background-color: white;
  padding: 2rem;
  .reset-btn-admin {
    width: 100%;
  }
}
