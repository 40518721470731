.coupon-input {
  // width: 40rem;
  height: 3rem;
}
.coupon-form {
  max-width: 80% !important;
}
// .coupon-tree{
//     margin-left: 2rem;

// }
@media screen and (max-width: 1440px) {
  .coupon-form {
    max-width: 100% !important;
  }
  .coupon-input {
    width: 35rem;
  }
}

@media screen and (max-width: 480px) {
  .coupon-input {
    width: 20rem;
  }
}

.productDropdown {
  display: flex;
  align-items: center;
}
