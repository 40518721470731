@import "../../../../../shared/style/Variables.scss";

.contentPadding {
  padding: 0px 5rem;
}

.detail-img-container {
  width: 18px;
  margin-right: 8px;
}
.detail-img {
  width: 100%;
  object-fit: contain;
}

.my-order-bgcolor,
.my-order-main-content-layout {
  background-color: $white;
}

.ant-breadcrumb-link,
.my-order-breadcrumb-active {
  font-size: 16px;
}

.my-order-breadcrumb-active {
  color: $primary-color;
  font-weight: 600;
}

.download-row {
  width: 100%;
  justify-content: flex-end;
  .download-btn {
    height: fit-content;
    color: #343434 !important;
    border: 1px solid #dddddd !important;
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 5px;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
  }
}

.my-order-main-content-layout {
  padding: 40px 0;

  .title {
    width: 470px;
    height: 59px;
    text-align: center;
    font: normal normal bold 48px/58px Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
    margin: auto;
    margin-bottom: 40px;
  }
  .my-order-search {
    .my-order-search-btn {
      background-color: #f4f4f4;
      border-radius: 5px;
    }
  }
  .details {
    margin: 5px;
    margin-bottom: 20px;
    background-color: #f6f6f6;
    padding: 20px;

    .details-text {
      font-weight: bold;
      font-family: Roboto;
      letter-spacing: 0px;
      color: #222222;
      opacity: 1;
    }
    .details-amount {
      color: $primary-color;
      padding-left: 4px;
    }
  }
  .order-btn {
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 20px 0;
    min-width: 150px;
  }
  .divide {
    border: 1px solid #7b7b7b;
    opacity: 0.5;
    margin: 20px 0 !important;
  }
  .deliver-to {
    text-align: left;
    width: 10ch;
    font-size: 21px;
    font-weight: bold;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #383838;
    opacity: 1;
  }
  .deliver-title {
    text-align: left;
    font-size: 21px;
    font-weight: bold;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }
  .deliver-address {
    text-align: left;
    font: normal normal normal 21px/25px Roboto;
    letter-spacing: 0px;
    color: #959595;
    opacity: 1;
  }
}

.productcard {
  display: flex;
  padding: 10px;
  img {
    border-radius: 5px;
    object-fit: cover;
    width: 250px;
    height: 150px;
  }
  .product-details {
    padding: 0 15px;
  }

  .product-title {
    text-align: left;
    margin: 0;
    font: normal normal normal 24px/29px Roboto;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
  }
  .product-sku {
    text-align: left;
    font: normal normal normal 16px/19px Roboto;
    letter-spacing: 0px;
    color: #808080;
    opacity: 1;
  }
}
.myOrderActionBtns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .theme-btn1 {
    height: fit-content;
    color: #343434 !important;
    border: 1px solid #dddddd !important;
    span {
      margin-right: 8px;
    }
  }
}
@media only screen and (max-width: 1280px) {
  .myOrdersBatch {
    .ant-badge-status-text {
      font-size: 18px !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .myOrderMainCardContainer {
    .infoContainer {
      width: 90%;

      .title {
        text-align: left !important;
        line-height: 1.2em !important;
      }
    }
  }
  .productcard {
    img {
      margin-right: 20px;
    }
    .product-title {
      font: normal normal normal 16px/29px Roboto;
    }
  }
  .my-order-main-content-layout {
    .order-btn {
      position: relative;
      left: -22px;
    }
    .details {
      .details-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    .deliver-title {
      font-size: 18px;
    }
    .deliver-address {
      font: normal normal normal 16px/22px Roboto;
      p:nth-child(1) {
        margin-bottom: 0px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .productcard {
    width: 100vw;
  }
  .my-order-main-content-layout {
    .order-btn {
      margin-left: 11px;
      bottom: 0;
    }

    overflow: hidden;
    .order-btn {
      width: fit-content;
      margin-left: 11px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .productcard {
    .product-details {
      padding: 0;
    }
    img {
      width: 70%;
      height: 100px;
    }
  }
  // .product-title{

  // }
  .my-order-main-content-layout {
    .order-btn {
      position: relative;
      left: 0px;
      margin-right: 20px;
    }

    .title {
      width: 100%;
      /* height: 59px; */
      text-align: center;
      font-size: 24px;
      font: normal normal bold 48px/58px Roboto;
      letter-spacing: 0px;
      color: #383838;
      opacity: 1;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .my-order-main-content-layout {
    overflow: hidden;
    .my-order-search {
      display: none;
    }
    .details {
      .details-text {
        font-size: 10px;
      }
    }
  }

  .myOrderMainCardContainer {
    flex-direction: column;

    width: 100%;
    .imgContainer {
      width: 100%;
    }
    .infoContainer {
      width: 90%;

      .title {
        font-size: 14px !important;

        text-align: left !important;
        line-height: 1.2em !important;
      }
      .sku {
        font-size: 12px !important;
      }
    }
  }
  .myOrderActionBtns {
    .theme-btn1 {
      font-size: 12px;
      padding: 10px 0px !important;
      white-space: pre-wrap !important;
    }
  }
  .deliver-to,
  .deliver-title {
    font-size: 16px !important;
  }
  .my-order-addres-bar,
  .my-order-addres-bar .right {
    padding: 0 !important;
  }

  .myOrdersBatch {
    span {
      font-size: 14px !important;
    }

    .ant-badge-status-text {
      font-size: 16px !important;
    }
  }
}

.myOrdersBatch {
  span {
    background-color: #26529e;
    text-align: center;
    color: white !important;
    padding: 5px 15px;
    font-size: 16px;
    display: inline-block;
    border-radius: 25px;
  }
  // display: flex;
  // justify-content: center;
  // span {
  //   display: flex;
  //   align-items: center;
  //   .ant-badge-status-dot {
  //     height: 15px;
  //     width: 15px;
  //     margin-right: 10px;
  //   }
  //   .ant-badge-status-text {
  //     font-size: 23px;
  //   }
  // }
}
